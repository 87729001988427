import firebase from 'firebase/app';

export const docFormater = (element: firebase.firestore.DocumentSnapshot) => {
  return {
    id: element.id,
    ...element.data(),
  };
};

export const splitTo10s = (ids: string[]) => {
  const array: string[][] = [];
  ids.forEach((id, index) => {
    if (index % 10 === 0) {
      array.push([id]);
    } else array[array.length - 1].push(id);
  });
  return array;
};

export const flatWhereIn10s = (
  data: firebase.firestore.QuerySnapshot<any>[],
) => {
  const formated = data
    .map(item =>
      item.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      }),
    )
    .flat();
  return formated;
};

export const mapTimestampsToDates = (
  doc: firebase.firestore.DocumentSnapshot,
) => {
  const data = {
    id: doc.id,
    ...doc.data(),
  };
  const newData: any = {};

  Object.entries(data).forEach(([key, value]: [string, any]) => {
    if (value instanceof firebase.firestore.Timestamp) {
      newData[key] = value.toDate();
    } else newData[key] = value;
  });

  return newData;
};

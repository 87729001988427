import algoliasearch from 'algoliasearch';
import { algoliaApiKey, algoliaAppId } from '../constants/constants';

export const serachClient = algoliasearch(algoliaAppId, algoliaApiKey);

export const productsIndex = serachClient.initIndex('products');
export const productsAscIndex = serachClient.initIndex('products_price_asc');
export const productsDescIndex = serachClient.initIndex('products_price_desc');
export const usersIndex = serachClient.initIndex('users');
export const postsIndex = serachClient.initIndex('forumPosts');

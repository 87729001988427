import {
  ActionTypes,
  SET_CHECKOUT_PAYMENT_METHOD,
  SET_CHECKOUT_BILLING_ADDRESS_ID,
  SET_CHECKOUT_SHIPPING_ADDRESS_ID,
  StateType,
} from '../types/CheckoutPage';

const initState: StateType = {
  // calc products in the confirm order Thunk
  method: '',
  paymentToken: '',
  billingAddressId: '',
  shippingAddressId: '',
  paymentValue: '',
};

const reducer = (state = initState, action: ActionTypes): StateType => {
  if (action.type === SET_CHECKOUT_SHIPPING_ADDRESS_ID) {
    const { id } = action.payload;
    return {
      ...state,
      shippingAddressId: id,
    };
  } else if (action.type === SET_CHECKOUT_BILLING_ADDRESS_ID) {
    const { id } = action.payload;
    return {
      ...state,
      billingAddressId: id,
    };
  } else if (action.type === SET_CHECKOUT_PAYMENT_METHOD) {
    const { type, token, value } = action.payload;
    return {
      ...state,
      method: type,
      paymentToken: token,
      paymentValue: value,
    };
  }
  return state;
};

export default reducer;

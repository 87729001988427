import { postsC, firestore, storage } from '../../utils/firebase-utils';
import Post, { postConverter } from '../../models/[new]post';
import { AppThunk } from '../store';
import { Something_Went_Wrong } from '../../utils/constants';
import {
  PostActionTypes,
  SEARCH_POSTS_REQUEST,
  SEARCH_POSTS_SUCCESS,
  SEARCH_POSTS_FAIL,
  GET_POSTS_FAIL,
  GET_POSTS_REQUEST,
  GET_POSTS_SUCCESS,
  ADD_POST_FAIL,
  ADD_POST_REQUEST,
  ADD_POST_SUCCESS,
  UPDATE_POST_FAIL,
  UPDATE_POST_REQUEST,
  UPDATE_POST_SUCCESS,
  LIKE_POST_FAIL,
  LIKE_POST_REQUEST,
  LIKE_POST_SUCCESS,
  DISLIKE_POST_FAIL,
  DISLIKE_POST_REQUEST,
  DISLIKE_POST_SUCCESS,
  GET_ONE_POST_REQUEST,
  GET_ONE_POST_SUCCESS,
  GET_ONE_POST_FAIL,
  UPDATE_COMMENTS_COUNT,
} from '../types/posts';
import { endPoint, storageFB } from '../../constants/constants';
import firebase from 'firebase';
import { postsIndex } from '../../utils/algolia';

// Search Posts
export const searchPostsRequest = (): PostActionTypes => {
  return {
    type: SEARCH_POSTS_REQUEST,
    payload: {},
  };
};
export const searchPostsSuccess = ({
  postsList,
}: {
  postsList: any[];
}): PostActionTypes => {
  return {
    type: SEARCH_POSTS_SUCCESS,
    payload: { postsList },
  };
};

export const searchPostsFail = ({
  error,
}: {
  error: string;
}): PostActionTypes => {
  return {
    type: SEARCH_POSTS_FAIL,
    payload: { error },
  };
};

export const searchPostsThunk = (query: string): AppThunk => {
  return async dispatch => {
    dispatch(searchPostsRequest());
    try {
      const algoliaResponse = await postsIndex.search(query);
      const postsFromAlgolia = algoliaResponse.hits;

      dispatch(searchPostsSuccess({ postsList: postsFromAlgolia }));
    } catch (e) {
      dispatch(searchPostsFail({ error: e.message || e.code }));
    }
  };
};

// Get One Post
export const getOnePostRequest = (): PostActionTypes => {
  return {
    type: GET_ONE_POST_REQUEST,
    payload: {},
  };
};
export const getOnePostSuccess = ({ post }: { post: any }): PostActionTypes => {
  return {
    type: GET_ONE_POST_SUCCESS,
    payload: { post },
  };
};

export const getOnePostFail = ({
  error,
}: {
  error: string;
}): PostActionTypes => {
  return {
    type: GET_ONE_POST_FAIL,
    payload: { error },
  };
};

export const getOnePostThunk = (postId: string): AppThunk => {
  return async dispatch => {
    dispatch(getOnePostRequest());
    try {
      const postSnapshot = await postsC
        .withConverter(postConverter)
        .doc(postId)
        .get();
      const post = postSnapshot.data();

      dispatch(getOnePostSuccess({ post: post }));
    } catch (e) {
      dispatch(getOnePostFail({ error: e.message || e.code }));
    }
  };
};

// Get All Posts
export const getPostsRequest = (): PostActionTypes => {
  return {
    type: GET_POSTS_REQUEST,
    payload: {},
  };
};
export const getPostsSuccess = ({
  postsList,
}: {
  postsList: Post[];
}): PostActionTypes => {
  return {
    type: GET_POSTS_SUCCESS,
    payload: { postsList },
  };
};

export const getPostsFail = ({ error }: { error: string }): PostActionTypes => {
  return {
    type: GET_POSTS_FAIL,
    payload: { error },
  };
};

export const getPostsThunk = (): AppThunk => {
  return async dispatch => {
    dispatch(getPostsRequest());
    try {
      const postsRef = await postsC
        .withConverter(postConverter)
        .orderBy('createdAt', 'desc')
        .get();
      const normalizedData = postsRef?.docs?.map(doc => {
        const data = doc?.data();
        if (data?.createdAt instanceof firebase.firestore.Timestamp) {
          // Convert Firebase timestamp to Unix timestamp (milliseconds)
          data.createdAt = data?.createdAt?.toMillis();
        }
        return data;
      });

      dispatch(getPostsSuccess({ postsList: normalizedData }));
    } catch (e) {
      dispatch(getPostsFail({ error: e.message || e.code }));
    }
  };
};

// Add New Post
export const addPostRequest = (): PostActionTypes => {
  return { type: ADD_POST_REQUEST, payload: {} };
};

export const addPostSuccess = ({
  id,
  content,
  images,
  likesCount,
  commentsCount,
  uid,
  userDisplayName,
  profilePicture,
}: {
  id: string;
  content: string;
  images: string[];
  likesCount?: number;
  commentsCount: number;
  uid: string;
  userDisplayName: string;
  profilePicture: string;
}): PostActionTypes => {
  return {
    type: ADD_POST_SUCCESS,
    payload: {
      id,
      content,
      images,
      likesCount,
      commentsCount,
      uid,
      userDisplayName,
      profilePicture,
    },
  };
};
export const addPostFail = ({ error }: { error: string }): PostActionTypes => {
  return {
    type: ADD_POST_FAIL,
    payload: {
      error,
    },
  };
};

export const addPostThunk = ({
  content,
  images = [],
  likesCount = 0,
  commentsCount = 0,
  userDisplayName,
  profilePicture,
  likedByIDs = [],
}: Post): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();
    dispatch(addPostRequest());
    try {
      const emptyDoc = await firestore.collection('forumPosts').doc();
      const downloadUrls: string[] = await Promise.all(
        images?.map(async (file: any) => {
          if (typeof file === 'string') return file;
          else {
            const ref = storage
              .ref()
              .child(`forumPosts/${emptyDoc?.id}/${file?.name}`);
            await ref.put(file);
            let newDownloadURL: string = '';
            await ref.getDownloadURL().then((downloadURL: string) => {
              downloadURL = downloadURL.replace(storageFB, endPoint);
              newDownloadURL = downloadURL;
            });
            return newDownloadURL;
          }
        }),
      );
      await postsC.withConverter(postConverter).doc(emptyDoc.id).set(
        {
          content,
          images: downloadUrls,
          likesCount,
          commentsCount,
          uid,
          userDisplayName,
          profilePicture,
          likedByIDs,
          id: emptyDoc.id,
          createdAt: new Date(),
          updatedAt: new Date(),
        },
        { merge: true },
      );

      dispatch(
        addPostSuccess({
          id: emptyDoc.id,
          content,
          commentsCount,
          images,
          uid,
          userDisplayName,
          profilePicture,
        }),
      );
      return emptyDoc.id;
    } catch (e) {
      console.log(e);
      dispatch(addPostFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};

// Update Post
export const updatePostRequest = (): PostActionTypes => {
  return {
    type: UPDATE_POST_REQUEST,
    payload: {},
  };
};

export const updatePostSuccess = ({
  id,
  content,
  images,
  userDisplayName,
  profilePicture,
}: {
  id: string;
  content: string;
  images: string[];
  userDisplayName: string;
  profilePicture: string;
}): PostActionTypes => {
  return {
    type: UPDATE_POST_SUCCESS,
    payload: {
      id,
      content,
      images,
      userDisplayName,
      profilePicture,
    },
  };
};

export const updatePostFail = ({
  error,
}: {
  error: string;
}): PostActionTypes => {
  return {
    type: UPDATE_POST_FAIL,
    payload: {
      error,
    },
  };
};

interface UpdatePostArgs {
  id: string;
  content: string;
  images: string[];
  userDisplayName: string;
  profilePicture: string;
}

export const updatePostThunk = ({
  id,
  content,
  images,
  userDisplayName,
  profilePicture,
}: UpdatePostArgs): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();
    dispatch(updatePostRequest());
    try {
      const downloadUrls: string[] = await Promise.all(
        images?.map(async (file: any) => {
          if (typeof file === 'string') return file;
          else {
            const ref = storage.ref().child(`forumPosts/${id}/${file?.name}`);
            await ref.put(file);
            let newDownloadURL: string = '';
            await ref.getDownloadURL().then((downloadURL: string) => {
              downloadURL = downloadURL.replace(storageFB, endPoint);
              newDownloadURL = downloadURL;
            });
            return newDownloadURL;
          }
        }),
      );
      await postsC.withConverter(postConverter).doc(id).set(
        {
          content,
          images: downloadUrls,
          userDisplayName,
          profilePicture,
          updatedAt: new Date(),
        },
        { merge: true },
      );

      dispatch(
        updatePostSuccess({
          id,
          content,
          images: downloadUrls,
          userDisplayName,
          profilePicture,
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(updatePostFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};

// Like Post
export const likePostRequest = (): PostActionTypes => {
  return {
    type: LIKE_POST_REQUEST,
    payload: {},
  };
};

export const likePostSuccess = ({
  id,
  uid,
}: {
  id: string;
  uid: string;
}): PostActionTypes => {
  return {
    type: LIKE_POST_SUCCESS,
    payload: { id, uid },
  };
};

export const likePostFail = ({ error }: { error: string }): PostActionTypes => {
  return {
    type: LIKE_POST_FAIL,
    payload: { error },
  };
};

export const likePostThunk = (postID: string): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();
    dispatch(likePostRequest());
    try {
      const increment = firebase.firestore.FieldValue.increment(1);

      await postsC
        .withConverter(postConverter)
        .doc(postID)
        .set(
          {
            likesCount: increment as unknown as number,
            likedByIDs: firebase.firestore.FieldValue.arrayUnion(
              uid,
            ) as unknown as string[],
          },
          { merge: true },
        );

      dispatch(
        likePostSuccess({
          id: postID,
          uid,
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(likePostFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};

// Dislike Post
export const dislikePostRequest = (): PostActionTypes => {
  return {
    type: DISLIKE_POST_REQUEST,
    payload: {},
  };
};

export const dislikePostSuccess = ({
  id,
  uid,
}: {
  id: string;
  uid: string;
}): PostActionTypes => {
  return {
    type: DISLIKE_POST_SUCCESS,
    payload: { id, uid },
  };
};

export const dislikePostFail = ({
  error,
}: {
  error: string;
}): PostActionTypes => {
  return {
    type: DISLIKE_POST_FAIL,
    payload: { error },
  };
};

export const dislikePostThunk = (postID: string): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();
    dispatch(dislikePostRequest());
    try {
      const decrement = firebase.firestore.FieldValue.increment(-1);

      await postsC
        .withConverter(postConverter)
        .doc(postID)
        .set(
          {
            likesCount: decrement as unknown as number,
            likedByIDs: firebase.firestore.FieldValue.arrayRemove(
              uid,
            ) as unknown as string[],
          },
          { merge: true },
        );

      dispatch(
        dislikePostSuccess({
          id: postID,
          uid,
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(dislikePostFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};

// Update Comments Count
export const updateCommentsCount = ({
  postId,
}: {
  postId: string;
}): PostActionTypes => {
  return {
    type: UPDATE_COMMENTS_COUNT,
    payload: { postId },
  };
};

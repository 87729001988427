import React, { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  Typography,
  Button,
  IconButton,
  Card,
  CardContent,
  CardActions,
  TextField,
  useTheme,
  useMediaQuery,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CustomButton from '../../../components/buttons/Button';
import { useSnackbar } from 'notistack';
import comonSnackOpts from '../../../utils/snackbar';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { updatePostThunk } from '../../../redux/actions/posts';
import { useTypedSelector } from '../../../redux/store';
import { Field, Formik, FormikHelpers } from 'formik';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '95%',
      margin: 'auto',
    },
    imgsContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '.1rem 0 ',
    },
    commentImg: {
      width: '100px',
      height: '100px',
      marginRight: '10px',
      borderRadius: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '55px',
        height: '55px',
      },
    },
    btnsWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1rem 2rem',
      [theme.breakpoints.down('xs')]: {
        padding: '1rem 0rem',
      },
    },
    imgBtn: {
      width: '10.8125rem',
      height: '2.125rem',
      color: '#898989',
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      borderRadius: '.25rem',
      border: '1px solid #EAEAEA',
    },
    postBtn: {
      width: '5rem',
      height: '2.125rem',
      fontSize: '0.75rem',
      fontFamily: 'Poppins',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '2rem 0',
    },
    txt: {
      fontWeight: 'bold',
      fontSize: '1.75rem',
      fontFamily: 'Arial',
      marginLeft: '1rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.4rem',
      },
    },
    brownDiv: {
      width: '0.375rem',
      height: '1.75rem',
      borderRadius: '0.625rem',
      background: '#D28B53',
    },

    fitContentHeight: {
      flexDirection: 'column',
      margin: 'auto',
    },
    descriptionField: {
      width: '100%',
      whiteSpace: 'pre-line',
      border: 'none',
    },
    postImageFeaturedStar: {
      position: 'absolute',
      top: -15,
      right: -10,
      color: '#B87A4E',
      opacity: 1,
    },
    modelBtnsWrapper: {
      width: '35%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const EditPost = (props: {
  setEditModal: (arg0: boolean) => void;
  editModal: boolean;
  post: any;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const submitLoading = useTypedSelector(state => state.UI.loaders.UPDATE_POST);
  const user = useTypedSelector(state => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const [imageFiles, setImageFiles] = useState<any>(props?.post?.images ?? []);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setImageFiles(props?.post?.images);
  }, [props?.post?.images]);

  const handleImageChange = (e: any) => {
    const selectedFiles = Array.from(e.target.files);
    if (
      imageFiles?.length + selectedFiles?.length > 5 ||
      selectedFiles?.length > 5
    ) {
      enqueueSnackbar("Images can't be more than 5", {
        variant: 'error',
        ...comonSnackOpts,
      });
      return;
    }
    setImageFiles((prevFiles: any) => [...prevFiles, ...selectedFiles]);
  };

  const handleCameraClick = () => {
    // Trigger the file input click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = (
    image: Blob | MediaSource | string,
    index: React.Key | null | undefined,
  ) => {
    if (!image) {
      return;
    }
    const removedFromImages = imageFiles?.filter((item: any) => {
      return item != image;
    });
    setImageFiles(removedFromImages);
  };

  const submitEditPost = async (
    values: { content: string },
    helpers: FormikHelpers<{ content: string }>,
  ) => {
    // Check if the value contains only spaces
    const containsOnlySpaces = /^\s+$/.test(values?.content);
    if (containsOnlySpaces) {
      enqueueSnackbar(
        'Please ensure that the content does not consist solely of spaces.',
        {
          variant: 'error',
          ...comonSnackOpts,
        },
      );
    } else {
      try {
        await dispatch(
          updatePostThunk({
            id: props.post?.id,
            images: imageFiles,
            content: values?.content,
            profilePicture: user?.profilePicture?.url,
            userDisplayName:
              (user?.userType === 'seller' && user
                ? user?.sellerData?.sellerName
                : user?.userType === 'shop' && user
                ? user?.shop?.shopName
                : user?.userType === 'buyer' && user
                ? user?.firstName
                : '') ?? '',
          }),
        );
        enqueueSnackbar('Post updated successfully', {
          variant: 'success',
          ...comonSnackOpts,
        });
        props.setEditModal(false);
      } catch (error) {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          ...comonSnackOpts,
        });
      }
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={props.editModal}
      onClose={() => props.setEditModal(false)}
    >
      <div
        style={{
          padding: '30px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle id="id">
          <Typography
            style={{ color: '#663C00' }}
            component="p"
            variant="subtitle1"
          >
            Edit your post
          </Typography>
        </DialogTitle>
        <IconButton onClick={() => props.setEditModal(false)}>
          <CloseIcon />
        </IconButton>
      </div>

      <Card className={classes.root}>
        <Formik
          enableReinitialize
          initialValues={{
            content: props?.post?.content ?? '',
          }}
          onSubmit={submitEditPost}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Field
                  as={TextField}
                  fullWidth
                  multiline
                  className={classes.descriptionField}
                  rows={6}
                  name="content"
                  placeholder="Type something here.."
                />
              </CardContent>
              <div className={classes.imgsContainer}>
                <input
                  type="file"
                  id="imageUpload"
                  name="imageUpload"
                  onChange={handleImageChange}
                  multiple
                  accept="image/*"
                  ref={fileInputRef}
                  hidden
                />
                <div style={{ display: 'flex' }}>
                  {imageFiles?.map(
                    (file: any, index: React.Key | null | undefined) => (
                      <div
                        role="button"
                        tabIndex={0}
                        style={{ position: 'relative' }}
                        key={`img : ${index}`}
                      >
                        <IconButton
                          onClick={() => handleRemoveImage(file, index)}
                          className={classes.postImageFeaturedStar}
                          style={{ zIndex: '9999' }}
                        >
                          <BackspaceIcon />
                        </IconButton>
                        <img
                          key={index}
                          src={
                            typeof file === 'string'
                              ? file
                              : URL.createObjectURL(file)
                          }
                          alt={`Preview ${index}`}
                          className={classes.commentImg}
                        />
                      </div>
                    ),
                  )}
                </div>
              </div>
              <CardActions>
                <div className={classes.btnsWrapper}>
                  <Button
                    className={classes.imgBtn}
                    onClick={handleCameraClick}
                  >
                    <NoteAddOutlinedIcon />
                    Add Picture
                  </Button>

                  <CustomButton
                    loading={submitLoading}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={
                      values?.content === '' && imageFiles?.length === 0
                        ? true
                        : false
                    }
                    className={classes.postBtn}
                  >
                    Save
                  </CustomButton>
                </div>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </Dialog>
  );
};

export default EditPost;

import React, { useEffect } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Menu,
  Button,
  Divider,
  Typography,
  withStyles,
  Badge,
  IconButton,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';

import { useTypedSelector } from '../../../redux/store';
import { NumericFormat } from 'react-number-format';
import { deleteFromCartThunk, getCartThunk } from '../../../redux/actions/cart';
import { getCartProductsSelector } from '../../../redux/actions/products';
import priceFormater from '../../../utils/priceFormater';
import useCartTotals from '../../../hooks/cartTotalsHook';
import ProductImageWithPadge from '../../ProductImageWithPadge';
import CheckoutBtn from '../../buttons/CheckoutBtn';
import CategoriesButton from '../../buttons/CategoriesButton';
import emptyCard from '../../../assets/empty_bag.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '50px', // button height is 48px
      maxWidth: 'initial',
      minWidth: '300px !important',
    },
    menuContainer: {
      padding: '1rem !important',
    },
    menuHead: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: '300px !important',
      alignItems: 'center',
    },
    totalWrapper: {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: '1rem',
    },
    headDivider: {
      margin: '1rem',
    },
    bottomDivider: {
      margin: '1rem',
    },
    itemsCount: {
      padding: '8px 6px',
    },
    productInfo: {
      marginRight: '0.25rem',
    },
    productImage: {},
    itemsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '0.5rem',
    },
    productItem: {
      width: '100%',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1rem',
      padding: '0.5rem',
      cursor: 'pointer',
      '&:hover': {
        background: '#ccc',
      },
    },
    productPriceQuantity: {
      color: '#00000061',
    },
    total: {
      fontWeight: 'bold',
    },
    totalLabel: {
      fontWeight: 'bold',
    },
    checkoutBtnWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    checkoutBtn: {
      flex: 1,
    },
    cartContent: {},
    emptyCartSvg: {
      maxWidth: '10rem',
      maxHeight: '10rem',
      height: 'fit-content',
      marginBottom: '1rem',
    },
    cartEmptyContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    listBtn: {
      color: '#574146',
      fontWeight: 'bold',
      '&:hover': {
        borderBottom: '2px solid #FDBB84',
      },
    },
  }),
);

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: 8,
      top: 7,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      color: 'white',
    },
  }),
)(Badge);

interface Props {
  className?: string;
}

const CartIconMenu = ({ className }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const cart = useTypedSelector(state => state.cart.cart);
  const { totalOrders } = useCartTotals();
  const productsInCart = useTypedSelector(getCartProductsSelector);
  const uid = useTypedSelector(state => state.user.uid);
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });

  const numOfProducts =
    cart?.length > 0
      ? cart
          .map(item => item?.quantity)
          .reduce((acc, currentValue) => acc + currentValue)
      : 0;

  useEffect(() => {
    if (uid) {
      dispatch(getCartThunk());
    }
  }, [dispatch, uid]);

  const handleDelete = (id: string) => {
    dispatch(deleteFromCartThunk({ id }));
  };
  const toProductPage = (slug_title: string) => {
    history.push(`/product${slug_title}`);
  };

  const viewCartPage = () => {
    history.push('/cart/bag');
  };

  const viewCheckoutPage = () => {
    history.push('/cart/checkout');
  };

  // useEffect(() => {
  //   if (productsInCart?.some(prod => prod?.isSold)) {
  //     enqueueSnackbar('One of your items is sold out.', {
  //       variant: 'error',
  //       ...comonSnackOpts,
  //     });
  //   } else if (productsInCart?.some(prod => prod?.paymentType ===  0)) {
  //     enqueueSnackbar('One of your items is cash only.', {
  //       variant: 'error',
  //       ...comonSnackOpts,
  //     });
  //   } else if (productsInCart?.some(prod => prod?.isDeleted)) {
  //     enqueueSnackbar('One of your items is deleted.', {
  //       variant: 'error',
  //       ...comonSnackOpts,
  //     });
  //   }
  // }, []);

  return (
    <>
      <StyledBadge
        {...bindTrigger(popupState)}
        badgeContent={numOfProducts ?? ''}
        color="secondary"
      >
        {/* <Button className={classes.listBtn}>Cart</Button> */}
        <IconButton>

        <img
          loading="lazy"
          src={require('../../../assets/landing-page/cart.png')}
          alt="cart"
          width="24px"
          height="24px"
        />
        </IconButton>
      </StyledBadge>
      {/* <BadgeIconBtn
        tooltipTitle="Cart"
        className={className}
        content={numOfProducts ?? ''}
        btnProps={{ ...bindTrigger(popupState) }}
      >
        <BiCart />
      </BadgeIconBtn> */}
      <Menu {...bindMenu(popupState)} className={classes.root}>
        <div className={classes.menuContainer}>
          {cart?.length !== 0 ? (
            <>
              <div className={classes.menuHead}>
                <Typography
                  className={classes.itemsCount}
                  component="p"
                  variant="body1"
                >
                  {numOfProducts} ITEMS
                </Typography>
                <Button
                  onClick={viewCartPage}
                  variant="text"
                  color="primary"
                  disabled={
                    productsInCart?.some(
                      prod =>
                        prod?.isSold ||
                        prod?.paymentType === 0 ||
                        prod?.isDeleted,
                    )
                      ? true
                      : false
                  }
                >
                  VIEW CART
                </Button>
              </div>
              <Divider className={classes.headDivider} />
              <div className={classes.itemsContainer}>
                {productsInCart?.map(
                  pro =>
                    pro && (
                      <div
                        key={pro?.id}
                        className={classes.productItem}
                        onClick={() => toProductPage(pro?.slug_title)}
                      >
                        <div className={classes.productInfo}>
                          <Typography component="p" variant="body1">
                            {`${pro?.title.slice(0, 32)}${
                              pro?.title.slice(32) ? '...' : ''
                            }`}
                          </Typography>
                          <Typography
                            className={classes.productPriceQuantity}
                            component="p"
                            variant="body1"
                          >
                            {pro?.isSold
                              ? 'Sold'
                              : `${pro?.quantity} x ${priceFormater.format(
                                  pro?.price as number,
                                )} `}{' '}
                            {pro?.paymentType === 0 && '(Cash only)'}
                            <br></br>
                            {pro?.isDeleted && '(Deleted)'}
                          </Typography>
                        </div>
                        <ProductImageWithPadge
                          id={pro?.id}
                          url={pro?.images[0]}
                          handleDelete={() => handleDelete(pro?.id)}
                        />
                      </div>
                    ),
                )}
              </div>
              <Divider className={classes.bottomDivider} />

              <div className={classes.cartContent}>
                <div className={classes.totalWrapper}>
                  <Typography
                    className={classes.totalLabel}
                    component="p"
                    variant="body1"
                  >
                    TOTAL:
                  </Typography>
                  <Typography
                    className={classes.total}
                    component="p"
                    color="secondary"
                    variant="body1"
                  >
                    <NumericFormat
                      displayType="text"
                      value={totalOrders}
                      decimalScale={2}
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      prefix="$"
                    />
                    {/* {priceFormater.format(totalOrders)} */}
                  </Typography>
                </div>
                <div className={classes.checkoutBtnWrapper}>
                  <CheckoutBtn
                    onClick={viewCheckoutPage}
                    className={classes.checkoutBtn}
                    disabled={
                      productsInCart?.some(
                        prod =>
                          prod?.isSold ||
                          prod?.paymentType === 0 ||
                          prod?.isDeleted,
                      )
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={classes.cartEmptyContent}>
              <img src={emptyCard} className={classes.emptyCartSvg} alt="" />
              <Typography gutterBottom component="p" variant="body1">
                Your Cart is Empty
              </Typography>
              <CategoriesButton />
            </div>
          )}
        </div>
      </Menu>
    </>
  );
};

export default CartIconMenu;

import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { motion, Variants, Transition } from 'framer-motion';
import DoneIcon from '@material-ui/icons/Done';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core';
import CustomButton, { Props } from './Button';

type AddToCartBtnProps = Props & {
  added?: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    loadingContainer: {
      color: 'red',
      display: 'flex',
      marginRight: '0.5rem',
    },
    loadingCircle: {
      width: '0.5rem',
      height: '0.5rem',
      background: '#fff',
      borderRadius: '0.25rem',
      display: 'block',
      marginRight: '0.25rem',
    },
    btnContent: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const loadingContainerVariants: Variants = {
  start: {
    transition: {
      staggerChildren: 0.3,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const loadingCircleVariants: Variants = {
  start: {
    y: '0%',
  },
  end: {
    y: '-80%',
  },
};

const loadingCircleTransition: Transition = {
  duration: 0.2,
  yoyo: Infinity,
  ease: 'easeInOut',
};
const AddToCartBtn = ({
  children,
  onClick,
  loading,
  ...rest
}: AddToCartBtnProps) => {
  const classes = useStyles(rest);

  const [added, setAdded] = useState<any>(false);
  const [_loading, setLoading] = useState<any>(false);

  useEffect(() => {
    setLoading(loading);
    if (!loading) {
      setTimeout(() => {
        setAdded(false);
      }, 3000);
    }
  }, [loading, added]);

  const handleSubmitClick = (e: any) => {
    e.stopPropagation();
    setAdded(true);
    if (onClick && !_loading && !added) onClick(e);
  };

  return (
    <CustomButton
      className={classes.root}
      disableElevation
      variant={added ? 'outlined' : 'contained'}
      startIcon={!_loading && (added === true ? <DoneIcon /> : <AddIcon />)}
      color="primary"
      onClick={handleSubmitClick}
      {...rest}
    >
      <div className={classes.btnContent}>
        {_loading && (
          <motion.div
            className={classes.loadingContainer}
            variants={loadingContainerVariants}
            initial="start"
            animate="end"
          >
            <motion.span
              variants={loadingCircleVariants}
              transition={loadingCircleTransition}
              className={classes.loadingCircle}
            />
            <motion.span
              variants={loadingCircleVariants}
              transition={loadingCircleTransition}
              className={classes.loadingCircle}
            />
            <motion.span
              variants={loadingCircleVariants}
              transition={loadingCircleTransition}
              className={classes.loadingCircle}
            />
          </motion.div>
        )}
        {!_loading && (
          <>
            {added === true ? ' Added To Cart' : 'Add To Cart'}

            {children}
          </>
        )}
      </div>
    </CustomButton>
  );
};

export default AddToCartBtn;

import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SmsIcon from '@material-ui/icons/Sms';
import SendIcon from '@material-ui/icons/Send';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Button,
} from '@material-ui/core';
import Post from '../../../models/[new]post';
import moment from 'moment';
import { useTypedSelector } from '../../../redux/store';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import comonSnackOpts from '../../../utils/snackbar';
import { addCommentThunk } from '../../../redux/actions/comments';
import { Carousel } from 'react-responsive-carousel';
import BackspaceIcon from '@material-ui/icons/Backspace';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { dislikePostThunk, likePostThunk } from '../../../redux/actions/posts';
import { Formik } from 'formik';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '70%',
      margin: '3rem 0',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    commentInput: {
      display: 'flex',
      height: '2.5rem',
      padding: '0.5rem 0.75rem',
      alignItems: 'center',
      gap: '0.5rem',
      flex: '1 0 0',
      borderRadius: '4.36763rem',
      border: '1.092px solid #EEE',
      background: '#FFF',
      margin: '0 1rem',
      [theme.breakpoints.down('xs')]: {
        margin: '0 .5rem',
      },
    },
    imgsContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '.1rem 0 ',
    },
    commentImg: {
      width: '100px',
      height: '100px',
      marginRight: '10px',
      borderRadius: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '55px',
        height: '55px',
      },
    },
    imgCarousel: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    postImageFeaturedStar: {
      position: 'absolute',
      top: -15,
      right: -10,
      color: '#B87A4E',
      opacity: 1,
    },
  }),
);

interface Props {
  post: Post;
  searchQuery: string;
}

export default function RecipeReviewCard({ post, searchQuery }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const user = useTypedSelector(state => state.user);
  const { uid } = useTypedSelector(state => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [commentText, setCommentText] = useState('');
  const [imageFiles, setImageFiles] = useState<any>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showFullPost, setShowFullPost] = useState(false);


  const postRef = useRef<HTMLInputElement | null>(null);

  // const togglePostVisibility = () => {
  //   setShowFullPost(!showFullPost);
  // };

  const togglePostVisibility = () => {
    setShowFullPost(showFullPost => !showFullPost);
    if ( postRef.current && showFullPost ) {
      postRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  

  const handleImageChange = (e: any) => {
    const selectedFiles = Array.from(e.target.files);
    if (
      imageFiles?.length + selectedFiles?.length > 5 ||
      selectedFiles?.length > 5
    ) {
      enqueueSnackbar("Images can't be more than 5", {
        variant: 'error',
        ...comonSnackOpts,
      });
      return;
    }
    setImageFiles((prevFiles: any) => [...prevFiles, ...selectedFiles]);
  };

  const handleCameraClick = () => {
    // Trigger the file input click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = (
    image: Blob | MediaSource,
    index: React.Key | null | undefined,
  ) => {
    if (!image) {
      return;
    }
    const removedFromImages = imageFiles?.filter((item: any) => {
      return item != image;
    });
    setImageFiles(removedFromImages);
  };

  const goToPostPage = (id: string | undefined) => {
    if (user?.uid) {
      history.push(`/post/${id}`);
    } else {
      history.push('/login');
    }
  };

  const handleComment = async (values: any) => {
    // Check if the value contains only spaces
    const containsOnlySpaces = /^\s+$/.test(commentText);

    if (containsOnlySpaces) {
      enqueueSnackbar(
        'Please ensure that the comment does not consist solely of spaces.',
        {
          variant: 'error',
          ...comonSnackOpts,
        },
      );
    } else {
      try {
        await dispatch(
          addCommentThunk({
            postID: post?.id,
            images: imageFiles,
            content: commentText,
            uid: user?.uid,
            profilePicture: user?.profilePicture?.url,
            userDisplayName:
              (user?.userType === 'seller' && user
                ? user?.sellerData?.sellerName
                : user?.userType === 'shop' && user
                ? user?.shop?.shopName
                : user?.userType === 'buyer' && user
                ? user?.firstName
                : '') ?? '',
          }),
        );
        enqueueSnackbar('Your comment has been successfully added.', {
          variant: 'success',
          ...comonSnackOpts,
        });
        setCommentText('');
        setImageFiles([]);
      } catch (e) {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          ...comonSnackOpts,
        });
      }
    }
  };

  const handleLikePost = async () => {
    if (post?.likedByIDs?.includes(user?.uid)) {
      await dispatch(dislikePostThunk(post?.id ?? ''));
    } else {
      await dispatch(likePostThunk(post?.id ?? ''));
    }
  };

  return (
    <Card className={classes.root} ref={postRef}>
      <CardHeader
        style={{ cursor: 'pointer' }}
        onClick={() => goToPostPage(post?.id)}
        avatar={
          <Avatar
            aria-label="recipe"
            className={classes.avatar}
            src={post?.profilePicture}
          >
            {post?.userDisplayName?.[0]}
          </Avatar>
        }
        action={
          post?.uid === user?.uid ? (
            <IconButton aria-label="settings">
              <EditOutlinedIcon />
            </IconButton>
          ) : (
            ''
          )
        }
        title={post?.userDisplayName}
        subheader={
          post?.createdAt
            ? moment(new Date(post?.createdAt)).fromNow()
            : 'just now'
        }
      />
      <CardContent>
        <Typography variant="body2" color="textPrimary" component="p">
          {showFullPost
            ? post?.content
            : post?.content?.length > 200
            ? `${post?.content?.slice(0, 200)}...`
            : post?.content}
        </Typography>
        {post?.content?.length > 200 && (
          <Button
            onClick={togglePostVisibility}
            style={{
              fontWeight: 'bold',
              color: 'rgb(152, 69, 0)',
              textTransform: 'capitalize',
            }}
          >
            {showFullPost ? 'Read Less' : 'Read More'}
          </Button>
        )}
      </CardContent>
      <Carousel swipeable={true}>
        {post?.images?.map((img, index) => {
          return (
            <div
              key={index}
              style={{
                height: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={img}
                alt={`pic ${index}`}
                className={classes.imgCarousel}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                }}
              />
            </div>
          );
        })}
      </Carousel>
      <CardActions disableSpacing>
        {uid && (
          <IconButton aria-label="add to favorites" onClick={handleLikePost}>
            {post?.likedByIDs?.includes(user?.uid) ? (
              <FavoriteIcon style={{ color: '#E74040' }} />
            ) : (
              <FavoriteBorderIcon style={{ color: '#E74040' }} />
            )}{' '}
            {post?.likesCount}
          </IconButton>
        )}
        <IconButton
          aria-label="share"
          onClick={() => goToPostPage(post?.id)}
          style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }} // Use flexbox properties for horizontal alignment
        >
          <SmsIcon />
          <span style={{ marginLeft: '2px', marginBottom: '0px' }}>
            {post?.commentsCount}
          </span>{' '}
          {/* Add a span with margin */}
        </IconButton>
      </CardActions>
      {uid && (
        <CardActions>
          <Formik
            enableReinitialize
            initialValues={{
              comment: '',
            }}
            onSubmit={handleComment}
          >
            {({ handleSubmit, setFieldValue }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  aria-label="recipe"
                  className={classes.avatar}
                  src={user?.profilePicture?.url}
                >
                  {user?.userType === 'seller'
                    ? user?.sellerData?.sellerName?.[0]
                    : user?.userType === 'shop'
                    ? user?.shop?.shopName?.[0]
                    : user?.firstName?.[0]}
                </Avatar>
                <input
                  placeholder="Add a comment"
                  className={classes.commentInput}
                  value={commentText}
                  onChange={e => setCommentText(e.target.value)}
                  name="comment"
                />
                <div>
                  <IconButton
                    type="submit"
                    disabled={
                      commentText === '' && imageFiles?.length === 0
                        ? true
                        : false
                    }
                  >
                    <SendIcon />
                  </IconButton>
                  <IconButton onClick={handleCameraClick}>
                    <CameraAltIcon />
                  </IconButton>
                </div>
              </form>
            )}
          </Formik>
        </CardActions>
      )}
      <div className={classes.imgsContainer}>
        <input
          type="file"
          id="imageUpload"
          name="imageUpload"
          onChange={handleImageChange}
          multiple
          accept="image/*"
          ref={fileInputRef}
          hidden
        />
        <div style={{ display: 'flex' }}>
          {imageFiles?.map(
            (file: Blob | MediaSource, index: React.Key | null | undefined) => (
              <div
                role="button"
                tabIndex={0}
                style={{ position: 'relative' }}
                key={`img : ${index}`}
              >
                <IconButton
                  onClick={() => handleRemoveImage(file, index)}
                  className={classes.postImageFeaturedStar}
                  style={{ zIndex: '9999' }}
                >
                  <BackspaceIcon />
                </IconButton>
                <img
                  key={index}
                  src={URL.createObjectURL(file)}
                  alt={`Preview ${index}`}
                  className={classes.commentImg}
                />
              </div>
            ),
          )}
        </div>
      </div>
    </Card>
  );
}

import { AppThunk } from '../store';
import {
  ActionTypes,
  SET_CHECKOUT_BILLING_ADDRESS_ID,
  SET_CHECKOUT_SHIPPING_ADDRESS_ID,
  SET_CHECKOUT_PAYMENT_METHOD,
} from '../types/CheckoutPage';

import { addAddressThunk, AddAddressThunkParams } from './addresses';

export const setSelectedShippingAddressId = ({
  id,
}: {
  id: string;
}): ActionTypes => {
  return {
    type: SET_CHECKOUT_SHIPPING_ADDRESS_ID,
    payload: {
      id,
    },
  };
};

export const setSelectedBillingAddressId = ({
  id,
}: {
  id: string;
}): ActionTypes => {
  return {
    type: SET_CHECKOUT_BILLING_ADDRESS_ID,
    payload: {
      id,
    },
  };
};

export const setSelectedPaymentMethod = ({
  type,
  token,
  value,
}: {
  type: 'CARD';
  token: string;
  value: string;
}): ActionTypes => {
  return {
    type: SET_CHECKOUT_PAYMENT_METHOD,
    payload: {
      type,
      token,
      value,
    },
  };
};

// export const addShippingAddress = ({
//   addressLineOne,
//   addressLineTwo,
//   placeId,
//   lat,
//   lng,
//   city,
//   state,
//   zipCode,
//   country,
//   name,
// }: Address): ActionTypes => {
//   return {
//     type: ADD_SHIPPING_ADDRESS,
//     payload: {
//       addressLineOne,
//       addressLineTwo,
//       placeId,
//       lat,
//       lng,
//       city,
//       state,
//       zipCode,
//       country,
//       name,
//     },
//   };
// };

// export const getSelectedShippingAddress = ({
//   addressLineOne,
//   addressLineTwo,
//   country,
//   city,
//   state,
//   zipCode,
//   lat,
//   lng,
//   name,
//   placeId,
// }: Address): ActionTypes => {
//   return {
//     type: GET_SELECTED_SHIPPING_ADDRESS,
//     payload: {
//       addressLineOne,
//       addressLineTwo,
//       country,
//       state,
//       city,
//       zipCode,
//       lat,
//       lng,
//       placeId,
//       name,
//     },
//   };
// };

// export const getSelectedShippingAddressId = ({
//   id,
// }: {
//   id: string;
// }): ActionTypes => {
//   return {
//     type: GET_SELECTED_SHIPPING_ADDRESS_ID,
//     payload: {
//       id,
//     },
//   };
// };

export const addShippingAddressThunk = (
  args: AddAddressThunkParams,
): AppThunk => {
  return async dispatch => {
    const addedAddressId = await dispatch(addAddressThunk(args));
    await dispatch(
      setSelectedShippingAddressId({
        id: addedAddressId as unknown as string,
      }),
    );
    await dispatch(
      // TODO, for now keep it but should be moved to her thunk later
      setSelectedBillingAddressId({
        id: addedAddressId as unknown as string,
      }),
    );
  };
};

export const addBillingAddressThunk = (
  args: AddAddressThunkParams,
): AppThunk => {
  return async dispatch => {
    const addedAddressId = await dispatch(addAddressThunk(args));
    await dispatch(
      setSelectedBillingAddressId({
        id: addedAddressId as unknown as string,
      }),
    );
  };
};

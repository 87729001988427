import React from 'react';
import { Avatar, AvatarProps } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

export interface Props extends AvatarProps {
  firstName?: string;
  clickable?: boolean;
  userId?: string;
  lastName?: string;
  avatarUrl?: string;
  [x: string]: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#fff',
      width: '50px',
      height: '50px',
    },
    clickable: {
      cursor: 'pointer',
    },
  }),
);

const UserAvatar: React.FC<Props> = ({
  firstName,
  lastName,
  avatarUrl,
  userId,
  className,
  clickable,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    if (userId) history.push(`/profile/${userId}`);
  };

  return (
    <Avatar
      className={clsx(classes.root, clickable && classes.clickable, className)}
      alt="avatar"
      src={avatarUrl}
      {...rest}
      onClick={handleClick}
    >
      {!avatarUrl
        ? firstName && firstName?.slice(0, 1) + lastName?.slice(0, 1)
        : require('../../assets/blank-profile-picture.jpg')}
    </Avatar>
  );
};

export default UserAvatar;

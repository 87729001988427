import React, { useState } from 'react';
import {
  makeStyles,
  createStyles,
  Dialog,
  Typography,
  TextField,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import comonSnackOpts from '../../../utils/snackbar';
import { auth } from '../../../utils/firebase-utils';
import { DialogTitle, DialogContent } from '../../../components/CustomDialog';
import CustomButton from '../../../components/buttons/Button';

type Props = {
  handleClose: () => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      display: 'contents',
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    helperText: {
      marginBottom: '2rem',
    },
    input: {
      marginBottom: '2rem',
    },
    error:{
      marginBottom: '2rem',
      color:'red'
    }
  }),
);

const ForgotPasswordDialog = ({ handleClose }: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [error,setError]=useState('');

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setError('')
    setValue(value);
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await auth.sendPasswordResetEmail(value);
      setLoading(false);
      enqueueSnackbar('Please check your email to rest your password', {
        variant: 'success',
        ...comonSnackOpts,
      });
      handleClose();
    } catch (e) {
      setLoading(false)
      switch (e.code) {
        case "auth/user-not-found":
          return setError("Couldn't find your account.");
        case "auth/invalid-email":
          return setError("Please enter a valid Email Address.");
        default:
          return setError("Something went wrong on our side.");
      }
    }
  }
  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle onClose={handleClose} id="forgot-password">
        Forgot Password
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.formWrapper}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Typography
              className={classes.helperText}
              variant="body1"
              component="p"
              gutterBottom
            >
              Please enter you email to restore your password
            </Typography>
            <TextField
              className={classes.input}
              label="Email *"
              placeholder="please enter your email"
              variant="outlined"
              value={value}

              onChange={handleChange}
            />
            {error !=='' &&
            <Typography
              className={classes.error}
              variant="caption"
              component="p"
              gutterBottom
            >
              {error}
            </Typography>
            }
            <CustomButton
              disabled={value.length < 3}
              type="submit"
              color="primary"
              variant="contained"
              loading={loading}
            >
              Send Password Rest
            </CustomButton>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ForgotPasswordDialog;

import React, { useState, useEffect, useRef } from 'react';
import {
  CardActions,
  Avatar,
  Typography,
  IconButton,
  createStyles,
  Theme,
  makeStyles,
  Modal,
  Fade,
  Backdrop,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SendIcon from '@material-ui/icons/Send';
import moment from 'moment';
import firebase from 'firebase/app';
import { useTypedSelector } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import {
  dislikeCommentThunk,
  likeCommentThunk,
  getCommentsThunk,
} from '../../../redux/actions/comments';
import { addReplyThunk, getRepliesThunk } from '../../../redux/actions/replies';
import { red } from '@material-ui/core/colors';
import { useSnackbar } from 'notistack';
import comonSnackOpts from '../../../utils/snackbar';
import CommentType from '../../../models/[new]comment';
import { useHistory } from 'react-router';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Formik } from 'formik';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: red[500],
      cursor: 'pointer',
    },
    commentInput: {
      display: 'flex',
      height: '2.5rem',
      padding: '0.5rem 0.75rem',
      alignItems: 'center',
      gap: '0.5rem',
      flex: '1 0 0',
      borderRadius: '4.36763rem',
      border: '1.092px solid #EEE',
      background: '#FFF',
      margin: '0 1rem',
      [theme.breakpoints.down('xs')]: {
        margin: '0 .5rem',
      },
    },
    commentOwner: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      fontFamily: 'Poppins',
    },
    commentContent: {
      color: '#141211',
      fontFamily: 'Poppins',
      fontSize: '1rem',
      margin: '.5rem 0',
    },
    commentDate: {
      color: '#828282',
      fontFamily: 'Poppins',
      fontSize: '.7rem',
      marginRight: '1rem',
    },
    commentReply: {
      color: '#242424',
      fontFamily: 'Poppins',
      fontSize: '.7rem',
      cursor: 'pointer',
    },
    commentActions: {
      display: 'flex',
    },
    repliesContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0.625rem 1rem .625rem 1rem',
      gap: '1rem',
      borderRadius: '0.5rem',
      background: '#F8F8F8',
      width: '85%',
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        width: '95%',
        padding: '0.625rem .5rem .625rem .5rem',
      },
    },
    breaker: {
      width: '100%',
      height: '0.0625rem',
      background: '#E7E7E7',
    },
    commentImg: {
      width: '100px',
      height: '100px',
      marginRight: '10px',
      borderRadius: '10px',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        width: '55px',
        height: '55px',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

interface Props {
  comment: CommentType;
  postID: string;
}

const Comment = ({ comment, postID }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useTypedSelector(state => state.user);
  const { uid } = useTypedSelector(state => state.user);
  const { commentsList } = useTypedSelector(state => state.commentsReducer);
  const { repliesByCommentId } = useTypedSelector(
    state => state.repliesReducer,
  );
  const { enqueueSnackbar } = useSnackbar();
  const [replyText, setReplyText] = useState('');
  const [openImg, setOpenImg] = React.useState(false);
  const [zoomImg, setZoomImg] = React.useState('');
  const [showFullContent, setShowFullContent] = useState(false);
  const [showFullReply, setShowFullReply] = useState(false);
  const postRef = useRef<HTMLInputElement | null>(null);

  const toggleContentVisibility = () => {
    setShowFullContent(!showFullContent);
  };

  

  // const toggleReplyVisibility = () => {
  //   setShowFullReply(!showFullReply);
  // };

  const toggleReplyVisibility = () => {
    setShowFullReply(showFullReply => !showFullReply);
    if ( postRef.current && showFullReply ) {
      postRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (postID && comment) {
      dispatch(getCommentsThunk(postID));
    }
  }, [postID]);

  const handleOpenImg = (img: React.SetStateAction<string>) => {
    setZoomImg(img);
    setOpenImg(img === '' ? false : true);
  };

  const handleCloseImg = () => {
    setOpenImg(false);
  };

  const fetchComments = () => {
    dispatch(getCommentsThunk(postID));
  };

  const fetchReplies = () => {
    if (comment?.id) {
      dispatch(getRepliesThunk(postID, comment?.id));
    }
  };

  
  const handleLikeComment = async (commentId: string) => {
    const curComment = commentsList?.find(c => c?.id === commentId);
    if (curComment?.likedByIDs?.includes(user?.uid)) {
      await dispatch(dislikeCommentThunk(commentId, postID));
    } else {
      await dispatch(likeCommentThunk(commentId, postID));
    }
    fetchComments();
  };

  const handleReply = async (values: any) => {
    // Check if the value contains only spaces
    const containsOnlySpaces = /^\s+$/.test(replyText);

    if (containsOnlySpaces) {
      enqueueSnackbar(
        'Please ensure that the reply does not consist solely of spaces.',
        {
          variant: 'error',
          ...comonSnackOpts,
        },
      );
    } else {
      try {
        await dispatch(
          addReplyThunk({
            postID: postID,
            commentId: comment?.id,
            content: replyText,
            uid: user?.uid,
            profilePicture: user?.profilePicture?.url,
            userDisplayName:
              (user?.userType === 'seller' && user
                ? user?.sellerData?.sellerName
                : user?.userType === 'shop' && user
                ? user?.shop?.shopName
                : user?.userType === 'buyer' && user
                ? user?.firstName
                : '') ?? '',
          }),
        );

        fetchComments();
        fetchReplies();
        enqueueSnackbar('Your reply has been successfully added.', {
          variant: 'success',
          ...comonSnackOpts,
        });
        setReplyText('');
      } catch (e) {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          ...comonSnackOpts,
        });
      }
    }
  };

  return (
    <>
      <CardActions
        disableSpacing
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Avatar
            aria-label="recipe"
            className={classes.avatar}
            src={comment?.profilePicture}
            onClick={() => history.push(`/profile/${comment?.uid}`)}
          >
            {comment?.userDisplayName?.[0]}
          </Avatar>
          <div style={{ paddingLeft: '15px' }}>
            <Typography className={classes.commentOwner}>
              {comment?.userDisplayName}
            </Typography>
            <div>
              <Typography className={classes.commentContent}>
                {showFullContent
                  ? comment?.content
                  : comment?.content?.length > 100
                  ? `${comment?.content?.slice(0, 100)}...`
                  : comment?.content}
              </Typography>
              {comment?.content?.length > 100 && (
                <Button
                  onClick={toggleContentVisibility}
                  style={{
                    fontWeight: 'bold',
                    color: 'rgb(152, 69, 0)',
                    textTransform: 'capitalize',
                  }}
                >
                  {showFullContent ? 'Read Less' : 'Read More'}
                </Button>
              )}
            </div>
            <div>
              {comment?.images
                ?.map((img: string, index: React.Key | null | undefined) => (
                  <div
                    role="button"
                    tabIndex={0}
                    style={{ position: 'relative' }}
                    key={`img : ${index}`}
                    onClick={() => handleOpenImg(img)}
                  >
                    <img
                      key={index}
                      src={img}
                      alt={`Preview ${index}`}
                      className={classes.commentImg}
                    />
                  </div>
                ))
                ?.reduce((acc: React.ReactNode[][], item, index) => {
                  if (index % 3 === 0) {
                    acc?.push([item]);
                  } else {
                    acc[acc?.length - 1]?.push(item);
                  }
                  return acc;
                }, [])
                ?.map((line, lineIndex) => (
                  <div key={`line-${lineIndex}`} style={{ display: 'flex' }}>
                    {line}
                  </div>
                ))}
            </div>
            <div className={classes.commentActions}>
              <Typography className={classes.commentDate}>
                {comment?.createdAt
                  ? moment(comment?.createdAt?.toDate()).fromNow()
                  : 'just now'}
              </Typography>
            </div>
          </div>
        </div>
        {uid && (
          <IconButton
            aria-label="add to favorites"
            onClick={() => handleLikeComment(comment?.id ?? '')}
          >
            {comment?.likedByIDs?.includes(user?.uid) ? (
              <FavoriteIcon style={{ color: '#E74040' }} />
            ) : (
              <FavoriteBorderIcon style={{ color: '#E74040' }} />
            )}{' '}
            {comment?.likesCount}
          </IconButton>
        )}
      </CardActions>

      <div className={classes.repliesContainer}>
        <Accordion style={{ background: '#F8F8F8' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={() => fetchReplies()} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="">
              Replies ({comment?.repliesCount})
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {repliesByCommentId[comment?.id ?? '']?.map((reply: any) => {
              return (
                <>
                  <CardActions
                  ref={postRef}
                    disableSpacing
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Avatar
                      aria-label="recipe"
                      className={classes.avatar}
                      src={reply?.profilePicture}
                      onClick={() => history.push(`/profile/${reply?.uid}`)}
                    >
                      {reply?.userDisplayName?.[0]}
                    </Avatar>
                    <div style={{ paddingLeft: '15px' }}>
                      <Typography className={classes.commentOwner}>
                        {reply?.userDisplayName}
                      </Typography>
                      <div>
                        <Typography className={classes.commentContent}>
                          {showFullReply
                            ? reply?.content
                            : reply?.content?.length > 100
                            ? `${reply?.content?.slice(0, 100)}...`
                            : reply?.content}
                        </Typography>
                        {reply?.content?.length > 100 && (
                          <Button
                            onClick={toggleReplyVisibility}
                            style={{
                              fontWeight: 'bold',
                              color: 'rgb(152, 69, 0)',
                              textTransform: 'capitalize',
                            }}
                          >
                            {showFullReply ? 'Read Less' : 'Read More'}
                          </Button>
                        )}
                      </div>
                      <Typography className={classes.commentDate}>
                        {reply?.createdAt
                          ? moment(
                              reply?.createdAt instanceof
                                firebase.firestore.Timestamp
                                ? reply?.createdAt?.toDate()
                                : reply?.createdAt,
                            ).fromNow()
                          : 'just now'}
                      </Typography>
                    </div>
                  </CardActions>
                  <div className={classes.breaker}></div>
                </>
              );
            })}
          </AccordionDetails>

          {uid && (
            <CardActions>
              <Formik
                enableReinitialize
                initialValues={{
                  reply: '',
                }}
                onSubmit={handleReply}
              >
                {({ handleSubmit, setFieldValue }) => (
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      aria-label="recipe"
                      className={classes.avatar}
                      src={user?.profilePicture?.url}
                    >
                      {user?.userType === 'seller'
                        ? user?.sellerData?.sellerName?.[0]
                        : user?.userType === 'shop'
                        ? user?.shop?.shopName?.[0]
                        : user?.firstName?.[0]}
                    </Avatar>
                    <input
                      placeholder="Add a reply"
                      className={classes.commentInput}
                      value={replyText}
                      onChange={e => setReplyText(e.target.value)}
                      name="reply"
                    />
                    <div>
                      <IconButton
                        type="submit"
                        disabled={replyText === '' ? true : false}
                      >
                        <SendIcon />
                      </IconButton>
                    </div>
                  </form>
                )}
              </Formik>
            </CardActions>
          )}
        </Accordion>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openImg}
        onClose={handleCloseImg}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openImg}>
          <div className={classes.paper}>
            <img src={zoomImg} alt="" width={'500px'} height={'500px'}></img>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default Comment;

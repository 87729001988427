import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Badge,
  useTheme,  
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/CancelSharp';
import  useMediaQuery  from '@material-ui/core/useMediaQuery';  

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '50px', // button height is 48px
      maxWidth: 'initial',
      minWidth: '300px !important',
    },
    img: {
      objectFit: 'cover',
    },
    deleteIcon: {
      color: theme.palette.secondary.main,
    },
  }),
);

interface Props {
  id: string;
  url: string;
  handleDelete: () => any;
}

  const ProductImageWithPadge = ({ id, url, handleDelete }: Props) => {
  const classes = useStyles();
  
   const theme = useTheme();                                     
   const xsDown = useMediaQuery(theme.breakpoints.down('xs'));  

  return (
    <Badge
      badgeContent={<DeleteIcon className={classes.deleteIcon} />}
      onClick={e => {
        e.stopPropagation();
        handleDelete();
      }}
    >
      <img
        onClick={e => e.stopPropagation()}
        src={url}
        alt="product"
       // width="82px"                                                      
       // height="82px"                                                    
        className={classes.img}
        style={{width:xsDown?"60px":"82px", height:xsDown?"60px":"82px", marginRight:xsDown?"10px":"unset"}}
      />
    </Badge>
  );
};

export default ProductImageWithPadge;

import firebase from 'firebase/app';
import { ConnectAccount } from './connect-account';
import { CartItem } from './[new]product';
import { BirthDate } from '../redux/types/user';

export interface PrivateUserType {
  uid: string;
  email: string;
  gender: string;
  phoneNumber: string;
  taxRate: number;
  dob: firebase.firestore.Timestamp | Date | null;
  wishList: string[];
  defaultBillingAddress: string;
  defaultShippingAddress: string;
  bankAccount: ConnectAccount | null;
  ssn: string;
  bag: CartItem[];
  recentlyViewed: string[];
  birthDate: any | null;
  isDisabled: boolean;
}

export default class PrivateUser implements PrivateUserType {
  uid: string;
  email: string;
  gender: string;
  phoneNumber: string;
  taxRate: number;
  dob: firebase.firestore.Timestamp | null;
  wishList: string[];
  bankAccount: ConnectAccount | null;
  defaultBillingAddress: string;
  defaultShippingAddress: string;
  ssn: string;
  bag: CartItem[];
  recentlyViewed: string[];
  birthDate: any | null;
  isDisabled: boolean;

  constructor(data: PrivateUserType) {
    this.uid = data?.uid;
    this.email = data?.email;
    this.gender = data?.gender;
    this.phoneNumber = data?.phoneNumber ?? '';
    this.taxRate = data?.taxRate ?? '';
    this.dob = (data?.dob as firebase.firestore.Timestamp) ?? null;
    this.wishList = data?.wishList ?? [];
    this.defaultBillingAddress = data?.defaultBillingAddress ?? '';
    this.defaultShippingAddress = data?.defaultShippingAddress ?? '';
    this.ssn = data?.ssn ?? '';
    this.bankAccount = data?.bankAccount ?? null;
    this.bag = data?.bag ?? [];
    this.recentlyViewed = data?.recentlyViewed ?? [];
    this.birthDate = data?.birthDate ?? null;
    this.isDisabled = data?.isDisabled ?? false;
  }
  toJson(): PrivateUser {
    return Object.assign({}, this);
  }
}

export const privateUserConverter: firebase.firestore.FirestoreDataConverter<
  Partial<PrivateUserType>
> = {
  toFirestore: (data: PrivateUserType): firebase.firestore.DocumentData => {
    return {
      ...data,
    };
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ) => {
    const data = snapshot.data(options);
    return new PrivateUser(data as PrivateUserType);
  },
};

import {
  postsC,
  firestore,
  storage,
  commentsC,
} from '../../utils/firebase-utils';
import Comment, { commentConverter } from '../../models/[new]comment';
import { AppThunk } from '../store';
import { Something_Went_Wrong } from '../../utils/constants';
import {
  CommentActionTypes,
  ADD_COMMENT_FAIL,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAIL,
  LIKE_COMMENT_REQUEST,
  LIKE_COMMENT_SUCCESS,
  LIKE_COMMENT_FAIL,
  DISLIKE_COMMENT_REQUEST,
  DISLIKE_COMMENT_FAIL,
  DISLIKE_COMMENT_SUCCESS,
} from '../types/comment';
import { endPoint, storageFB } from '../../constants/constants';
import firebase from 'firebase';
import { postConverter } from '../../models/[new]post';
import { updateCommentsCount } from './posts';
import { postsIndex } from '../../utils/algolia';

// Add New Comment
export const addCommentRequest = (): CommentActionTypes => {
  return { type: ADD_COMMENT_REQUEST, payload: {} };
};

export const addCommentSuccess = ({
  id,
  content,
  images,
  likesCount,
  repliesCount,
  uid,
  userDisplayName,
  profilePicture,
  postID,
}: {
  id: string;
  content: string;
  images?: string[];
  likesCount?: number;
  repliesCount?: number;
  uid: string;
  userDisplayName: string;
  profilePicture: string;
  postID?: string;
}): CommentActionTypes => {
  return {
    type: ADD_COMMENT_SUCCESS,
    payload: {
      id,
      content,
      images,
      likesCount,
      repliesCount,
      uid,
      userDisplayName,
      profilePicture,
      postID,
    },
  };
};
export const addCommentFail = ({
  error,
}: {
  error: string;
}): CommentActionTypes => {
  return {
    type: ADD_COMMENT_FAIL,
    payload: {
      error,
    },
  };
};

export const addCommentThunk = ({
  postID,
  content,
  images = [],
  userDisplayName,
  profilePicture,
  likesCount = 0,
  repliesCount = 0,
  likedByIDs = [],
}: Comment): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();
    dispatch(addCommentRequest());
    try {
      const emptyDoc = await firestore
        .collection('forumPosts')
        .doc(postID)
        .collection('comments')
        .doc();
      const downloadUrls: string[] = await Promise.all(
        images?.map(async (file: any) => {
          if (typeof file === 'string') return file;
          else {
            const ref = storage
              .ref()
              .child(`forumPosts/${postID}/${emptyDoc?.id}/${file?.name}`);
            await ref.put(file);
            let newDownloadURL: string = '';
            await ref.getDownloadURL().then((downloadURL: string) => {
              downloadURL = downloadURL.replace(storageFB, endPoint);
              newDownloadURL = downloadURL;
            });
            return newDownloadURL;
          }
        }),
      );

      await postsC
        .doc(postID)
        .collection('comments')
        .withConverter(commentConverter)
        .doc(emptyDoc.id)
        .set(
          {
            content,
            images: downloadUrls,
            likesCount,
            repliesCount,
            uid,
            userDisplayName,
            profilePicture,
            likedByIDs,
            id: emptyDoc.id,
            createdAt: new Date(),
          },
          { merge: true },
        );

      const increment = firebase.firestore.FieldValue.increment(1);

      await postsC
        .withConverter(postConverter)
        .doc(postID)
        .set(
          {
            commentsCount: increment as unknown as number,
          },
          { merge: true },
        );

      dispatch(
        addCommentSuccess({
          id: emptyDoc.id,
          content,
          images,
          uid,
          userDisplayName,
          profilePicture,
          postID,
        }),
      );
      if (postID) {
        dispatch(updateCommentsCount({ postId: postID }));
      }

      return emptyDoc.id;
    } catch (e) {
      console.log(e);
      dispatch(addCommentFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};

// Get All Comments
export const getCommentsRequest = (): CommentActionTypes => {
  return { type: GET_COMMENTS_REQUEST, payload: {} };
};

export const getCommentsSuccess = ({
  commentsList,
}: {
  commentsList: Comment[];
}): CommentActionTypes => {
  return {
    type: GET_COMMENTS_SUCCESS,
    payload: {
      commentsList,
    },
  };
};
export const getCommentsFail = ({
  error,
}: {
  error: string;
}): CommentActionTypes => {
  return {
    type: GET_COMMENTS_FAIL,
    payload: {
      error,
    },
  };
};

export const getCommentsThunk = (postID: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(getCommentsRequest());
    try {
      const commentsRef = await commentsC(postID)
        .withConverter(commentConverter)
        .orderBy('createdAt', 'desc')
        .get();
      const commentsList = commentsRef.docs?.map(doc => doc?.data());

      dispatch(getCommentsSuccess({ commentsList }));
    } catch (e) {
      console.log(e);
      dispatch(getCommentsFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};

// Like Comment
export const likeCommentRequest = (): CommentActionTypes => {
  return {
    type: LIKE_COMMENT_REQUEST,
    payload: {},
  };
};

export const likeCommentSuccess = ({
  id,
}: {
  id: string;
}): CommentActionTypes => {
  return {
    type: LIKE_COMMENT_SUCCESS,
    payload: { id },
  };
};

export const likeCommentFail = ({
  error,
}: {
  error: string;
}): CommentActionTypes => {
  return {
    type: LIKE_COMMENT_FAIL,
    payload: { error },
  };
};

export const likeCommentThunk = (
  commentID: string,
  postID: string,
): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();
    dispatch(likeCommentRequest());
    try {
      const increment = firebase.firestore.FieldValue.increment(1);

      await commentsC(postID)
        .withConverter(commentConverter)
        .doc(commentID)
        .set(
          {
            likesCount: increment as unknown as number,
            likedByIDs: firebase.firestore.FieldValue.arrayUnion(
              uid,
            ) as unknown as string[],
          },
          { merge: true },
        );

      dispatch(
        likeCommentSuccess({
          id: commentID,
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(likeCommentFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};

// Dislike Post
export const dislikeCommentRequest = (): CommentActionTypes => {
  return {
    type: DISLIKE_COMMENT_REQUEST,
    payload: {},
  };
};

export const dislikeCommentSuccess = ({
  id,
}: {
  id: string;
}): CommentActionTypes => {
  return {
    type: DISLIKE_COMMENT_SUCCESS,
    payload: { id },
  };
};

export const dislikeCommentFail = ({
  error,
}: {
  error: string;
}): CommentActionTypes => {
  return {
    type: DISLIKE_COMMENT_FAIL,
    payload: { error },
  };
};

export const dislikeCommentThunk = (
  commentID: string,
  postID: string,
): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();
    dispatch(dislikeCommentRequest());
    try {
      const decrement = firebase.firestore.FieldValue.increment(-1);

      await commentsC(postID)
        .withConverter(commentConverter)
        .doc(commentID)
        .set(
          {
            likesCount: decrement as unknown as number,
            likedByIDs: firebase.firestore.FieldValue.arrayRemove(
              uid,
            ) as unknown as string[],
          },
          { merge: true },
        );

      dispatch(
        dislikeCommentSuccess({
          id: commentID,
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        dislikeCommentFail({ error: e.message || Something_Went_Wrong }),
      );
    }
  };
};

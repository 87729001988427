import Comment from '../../models/[new]comment';

export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAIL = 'ADD_COMMENT_FAIL';

export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAIL = 'GET_COMMENTS_FAIL';

export const LIKE_COMMENT_REQUEST = 'LIKE_COMMENT_REQUEST';
export const LIKE_COMMENT_SUCCESS = 'LIKE_COMMENT_SUCCESS';
export const LIKE_COMMENT_FAIL = 'LIKE_COMMENT_FAIL';

export const DISLIKE_COMMENT_REQUEST = 'DISLIKE_COMMENT_REQUEST';
export const DISLIKE_COMMENT_SUCCESS = 'DISLIKE_COMMENT_SUCCESS';
export const DISLIKE_COMMENT_FAIL = 'DISLIKE_COMMENT_FAIL';

// New Comment
interface AddCommentRequest {
  type: typeof ADD_COMMENT_REQUEST;
  payload: {};
}

interface AddCommentSuccess {
  type: typeof ADD_COMMENT_SUCCESS;
  payload: {
    id: string;
    content: string;
    images?: string[];
    likesCount?: number;
    repliesCount?: number;
    uid: string;
    userDisplayName: string;
    profilePicture: string;
    postID?: string;
  };
}

interface AddCommentFail {
  type: typeof ADD_COMMENT_FAIL;
  payload: {
    error: string;
  };
}

// Like Comment
interface LikeCommentRequest {
  type: typeof LIKE_COMMENT_REQUEST;
  payload: {};
}

interface LikeCommentSuccess {
  type: typeof LIKE_COMMENT_SUCCESS;
  payload: {
    id: string;
  };
}

interface LikeCommentFail {
  type: typeof LIKE_COMMENT_FAIL;
  payload: {
    error: string;
  };
}

// Dislike Comment
interface DislikeCommentRequest {
  type: typeof DISLIKE_COMMENT_REQUEST;
  payload: {};
}

interface DislikeCommentSuccess {
  type: typeof DISLIKE_COMMENT_SUCCESS;
  payload: {
    id: string;
  };
}

interface DislikeCommentFail {
  type: typeof DISLIKE_COMMENT_FAIL;
  payload: {
    error: string;
  };
}

// Get All Comments
interface GetCommentsRequest {
  type: typeof GET_COMMENTS_REQUEST;
  payload: {};
}

interface GetCommentsSuccess {
  type: typeof GET_COMMENTS_SUCCESS;
  payload: {
    commentsList: Comment[];
  };
}

interface GetCommentsFail {
  type: typeof GET_COMMENTS_FAIL;
  payload: {
    error: string;
  };
}

export type CommentActionTypes =
  | AddCommentRequest
  | AddCommentSuccess
  | AddCommentFail
  | LikeCommentRequest
  | LikeCommentSuccess
  | LikeCommentFail
  | DislikeCommentRequest
  | DislikeCommentSuccess
  | DislikeCommentFail
  | GetCommentsRequest
  | GetCommentsSuccess
  | GetCommentsFail;

export interface State {
  commentsList: Comment[];
  error: string;
}

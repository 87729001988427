import firebase from 'firebase/app';

interface UserErrors {
  user: string;
  email: string;
  password: string;
}
const firebaseAuthErrs = (error: firebase.auth.Error) => {
  const userErrors: UserErrors = {
    user: '',
    email: '',
    password: '',
  };
  switch (error.code) {
    case 'auth/user-not-found':
      userErrors.user = "Couldn't find your account.";
      break;
    case 'auth/wrong-password':
      userErrors.password = 'Incorrect password';
      break;
    case 'auth/invalid-email':
      userErrors.email = 'Please enter a valid Email';
      break;
    case 'auth/email-already-in-use':
      userErrors.email = 'Account already exists.';
      break;
    case 'auth/weak-password':
      userErrors.password = 'Password must contain 6+ characters';
      break;
    default:
      userErrors.user = 'Something went wrong.';
  }
  return userErrors;
};

export default firebaseAuthErrs;

// export const algoliaAppId = 'BWQD3S8023';
// export const algoliaApiKey = '429351a3ef792ef4f5cc366dc9a99bed';
// export const brainTreePaypalKey = 'sandbox_bn8fxppq_37r8hh82xrz7tfn4';

// production
/*export const algoliaAppId = 'N7FN3YV5OQ';
export const algoliaApiKey = '27601996e8cec252d96b4f8300bca322';
export const brainTreePaypalKey = 'sandbox_bn8fxppq_37r8hh82xrz7tfn4';*/

let algoliaAppId: string;
let algoliaApiKey: string;
let brainTreePaypalKey: string;
let endPoint = 'https://ik.imagekit.io/zeta';
let storageFB: string;

if (process.env.REACT_APP_ENV === 'production') {
  algoliaAppId = 'N7FN3YV5OQ';
  algoliaApiKey = '5b122d554fa45f24aaa95056f1d61b3d';
  brainTreePaypalKey = 'sandbox_bn8fxppq_37r8hh82xrz7tfn4';
  storageFB =
    'https://firebasestorage.googleapis.com/v0/b/antiquesmart-709cd.appspot.com';
} else {
  algoliaAppId = 'BWQD3S8023';
  algoliaApiKey = '24331a52e204bf0f9d48a7baa01dc2b4';
  brainTreePaypalKey = 'sandbox_bn8fxppq_37r8hh82xrz7tfn4';
  storageFB =
    'https://firebasestorage.googleapis.com/v0/b/antiqusmart.appspot.com';
}

export { algoliaAppId, algoliaApiKey, brainTreePaypalKey, endPoint, storageFB };

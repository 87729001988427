import {
  ActionTypes,
  ADD_CATEGORY_FILTER,
  CLEAR_PRODUCTS_LIST,
  CLEAR_SEARCH_FILTERS,
  CLEAR_SHOPS_LIST,
  GET_BEST_PRODUCTS_FAIL,
  GET_BEST_PRODUCTS_REQUEST,
  GET_BEST_PRODUCTS_SUCCESS,
  NO_RESULT,
  RADIUS_TYPES,
  REMOVE_CATEGORY_FILTER,
  SEARCH_FAIL,
  SEARCH_REQUEST,
  SEARCH_SHOPS_SUCCESS,
  SEARCH_SUCCESS,
  SearchPageState,
  SET_AGE_FILTER,
  SET_CATEGORY_FILTERS,
  SET_DISTANCE_RANGE_FILTER,
  SET_NO_RESULT,
  SET_NO_RESULT_SHOPS,
  SET_PAGE_NO,
  SET_PAGE_SIZE,
  SET_PRICE_RANGE_FILTER,
  SET_PRICE_SORT,
  SET_RADIUS_TYPE,
  SET_RESULTS_TYPE,
  SET_TEXT_FILTER,
  SET_ZIP_CODE_FILTER,
  SET_CURRENT_CATEGORY,
  SET_MAP_CENTER,
  SET_MAP_ZOOM,
} from '../types/search-page';

const initialState: SearchPageState = {
  error: '',
  list: [],
  shops: [],
  noResult: false,
  noResultShops: false,
  currentCategory: '',
  mapCenter: {
    lat: 0,
    lng: 0,
  },
  mapZoom: 2,
  searchFilters: {
    text: '',
    categories: [],
    itemsPerPage: 12,
    page: 0,
    priceRange: {
      from: 0,
      to: 0,
    },
    distance: {
      aroundLatLngViaIP: false,
      KMRadius: 10,
    },
    age: 1, // maps to recently in the product data model
    zipCode: null,
    priceSort: '',
    dateOfMadeSort: '',
    location: {
      lat: 0,
      lng: 0,
      loc: '',
    },
    radiusType: RADIUS_TYPES.DONT_LIMIT_RADIUS,
    resultsType: 'listings',
    
  },
};

const reducer = (
  state = initialState,
  action: ActionTypes,
): SearchPageState => {
  // console.log('type', action?.type);
  // console.log('data', action?.payload);
  // debugger;
  if (action.type === SET_ZIP_CODE_FILTER) {
    const { lat, lng, loc } = action.payload;
    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        location: {
          lat,
          lng,
          loc,
        },
      },
    };
  } else if (action.type === SET_MAP_CENTER) {
    const { mapCenter } = action.payload;
    return {
      ...state,
      mapCenter,
    };
  } else if (action.type === SET_MAP_ZOOM) {
    const { mapZoom } = action.payload;
    return {
      ...state,
      mapZoom,
    };
  } else if (action.type === SET_CURRENT_CATEGORY) {
    const { currentCategory } = action.payload;
    return {
      ...state,
      currentCategory,
    };
  } else if (action.type === SET_NO_RESULT) {
    const { noResult } = action.payload;
    return {
      ...state,
      noResult,
    };
  } else if (action.type === SET_NO_RESULT_SHOPS) {
    const { noResultShops } = action.payload;
    return {
      ...state,
      noResultShops,
    };
  } else if (action.type === SET_RADIUS_TYPE) {
    const { type } = action.payload;
    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        radiusType: type,
      },
    };
  } else if (action.type === SET_DISTANCE_RANGE_FILTER) {
    const { km } = action.payload;
    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        distance: {
          aroundLatLngViaIP: true,
          KMRadius: km,
        },
      },
    };
  } else if (action.type === GET_BEST_PRODUCTS_REQUEST) {
    return state;
  } else if (action.type === GET_BEST_PRODUCTS_SUCCESS) {
    const { products } = action.payload;
    return {
      ...state,
      error: '',
      list: products,
    };
  } else if (action.type === GET_BEST_PRODUCTS_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  }
  if (action.type === SET_TEXT_FILTER) {
    const { text } = action.payload;
    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        text,
      },
    };
  } else if (action.type === SET_PRICE_SORT) {
    const { sort } = action.payload;
    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        priceSort: sort,
      },
    };
    // todo SET_AGE_SORT
  } else if (action.type === SET_AGE_FILTER) {
    const { age } = action.payload;
    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        age,
      },
    };
  } else if (action.type === SET_PRICE_RANGE_FILTER) {
    const { from, to } = action.payload;
    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        priceRange: {
          from,
          to,
        },
      },
    };
  } else if (action.type === SET_PAGE_SIZE) {
    const { size } = action.payload;
    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        itemsPerPage: size,
      },
    };
  } else if (action.type === SET_PAGE_NO) {
    const { num } = action.payload;
    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        page: num,
      },
    };
  } else if (action.type === SET_CATEGORY_FILTERS) {
    const { categories } = action.payload;

    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        categories,
      },
    };
  } else if (action.type === ADD_CATEGORY_FILTER) {
    const {
      searchFilters: { categories },
    } = state;
    const { category } = action.payload;
    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        categories: [...categories, category],
      },
    };
  } else if (action.type === REMOVE_CATEGORY_FILTER) {
    const {
      searchFilters: { categories },
    } = state;
    const { category } = action.payload;
    const newCats = categories?.filter(cat => cat !== category);

    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        categories: newCats,
      },
    };
  } else if (action.type === SET_RESULTS_TYPE) {
    const { resultsType } = action.payload;

    return {
      ...state,
      searchFilters: {
        ...state.searchFilters,
        resultsType: resultsType,
      },
    };
  } else if (action.type === CLEAR_SEARCH_FILTERS) {
    return { ...initialState };
  } else if (action.type === SEARCH_REQUEST) {
    return state;
  } else if (action.type === NO_RESULT) {
    return state;
  } else if (action.type === SEARCH_SUCCESS) {
    const { list } = action.payload;
    return {
      ...state,
      list,
    };
  } else if (action.type === SEARCH_SHOPS_SUCCESS) {
    const { shops } = action.payload;
    return {
      ...state,
      shops,
    };
  } else if (action.type === SEARCH_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === CLEAR_PRODUCTS_LIST) {
    return {
      ...state,
      list: [],
    };
  } else if (action.type === CLEAR_SHOPS_LIST) {
    return {
      ...state,
      shops: [],
    };
  } else return state;
};

export default reducer;

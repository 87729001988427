import React, { useState } from 'react';
import {
  makeStyles,
  createStyles,
  Typography,
  Dialog,
  DialogProps,
  DialogTitle
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CustomButton from './buttons/Button';
import {  DialogContent } from './CustomDialog';
import CustomInput from './inputs/TextField';
import { setUsernameThunk } from '../redux/actions/user';
import { useTypedSelector } from '../redux/store';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: '200px',
    },
    width: {
      width: '600px',
    },
    desc: {
      color: '#00000061',
      marginBottom: '2rem',
    },
    form: {
      display: 'contents',
    },
    input: {},
    btnWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);

const UsernameDialog = (props: DialogProps) => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const dispatch = useDispatch();
  const error = useTypedSelector(state => state.user.error);
  const loading = useTypedSelector(state => state.UI.loaders.SET_USER);
  const savedUserName = useTypedSelector(state => state.user.userName);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUsername(value);
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(
      setUsernameThunk({
        username: username || ' ',
      }),
    );
  };

  const handleClose = () => {
    if (!username) {
      dispatch(setUsernameThunk({ username: ' ' }));
    }
  };

  return (
    <>
      {!savedUserName && (
        <Dialog
          className={classes.root}
          classes={{
            paperWidthSm: classes.width,
          }}
          disableBackdropClick={false}  // Enable clicking outside to close
          onClose={handleClose}  // Handle dialog close
          onBackdropClick={handleClose} 
          {...props}
        >
          <DialogTitle id='id' >
            Add User Name
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.desc} component='p' variant='body2'>
              Add your username to be able to add products to the cart and
              complete the shopping process
            </Typography>
            <form onSubmit={handleSubmit} className={classes.form}>
              <CustomInput
                className={classes.input}
                fullWidth
                placeholder='Enter a unique username'
                label='Username *'
                name='username'
                variant='outlined'
                value={username}
                onChange={handleChange}
                error={!!error}
                helperText={error && error}
              />
              <div className={classes.btnWrapper}>
                <CustomButton
                  loading={loading}
                  type='submit'
                  variant='contained'
                  color='primary'
                >
                  Complete Signup
                </CustomButton>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default UsernameDialog;

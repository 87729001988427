import { CartItem, Product } from '../../models/[new]product';

export const CLEAR_CART_REQUEST = 'CLEAR_CART_REQUEST';
export const CLEAR_CART_SUCCESS = 'CLEAR_CART_SUCCESS';
export const CLEAR_CART_FAIL = 'CLEAR_CART_FAIL';

export const ADD_CART_ITEM_REQUEST = 'ADD_CART_ITEM_REQUEST';
export const ADD_CART_ITEM_SUCCESS = 'ADD_CART_ITEM_SUCCESS';
export const ADD_CART_ITEM_FAIL = 'ADD_CART_ITEM_FAIL';

export const DELETE_CART_ITEM_REQUEST = 'DELETE_CART_ITEM_REQUEST';
export const DELETE_CART_ITEM_SUCCESS = 'DELETE_CART_ITEM_SUCCESS';
export const DELETE_CART_ITEM_FAIL = 'DELETE_CART_ITEM_FAIL';

export const UPDATE_CART_ITEM_REQUEST = 'UPDATE_CART_ITEM_REQUEST';
export const UPDATE_CART_ITEM_SUCCESS = 'UPDATE_CART_ITEM_SUCCESS';
export const UPDATE_CART_ITEM_FAIL = 'UPDATE_CART_ITEM_FAIL';

export const GET_CART_ITEMS_REQUEST = 'GET_CART_ITEMS_REQUEST';
export const GET_CART_ITEMS_SUCCESS = 'GET_CART_ITEMS_SUCCESS';
export const GET_CART_ITEMS_FAIL = 'GET_CART_ITEMS_FAIL';

export const SET_ITEM_SHIPPMENT = 'SET_ITEM_SHIPPMENT';
export const DELETE_ITEM_SHIPPMENT = 'DELETE_ITEM_SHIPPMENT';
export const UPDATE_ITEM_SHIPPMENT = 'UPDATE_ITEM_SHIPPMENT';
export const GET_ITEM_SHIPPMENT = 'SET_ITEM_SHIPPMENT';
export const RESET_CART_ERROR = 'RESET_CART_ERROR';

export const SUBMIT_ORDER_REQUEST = 'SUBMIT_ORDER_REQUEST';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_FAIL = 'SUBMIT_ORDER_FAIL';

// export const GET_CART_PRODUCTS_REQUEST = 'GET_CART_PRODUCTS_REQUEST';
// export const GET_CART_PRODUCTS_SUCCESS = 'GET_CART_PRODUCTS_SUCCESS';
// export const GET_CART_PRODUCTS_FAIL = ' GET_CART_PRODUCTS_FAIL';

// export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
// export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
// export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';

export const SET_TOTAL = 'SET_TOTAL';

// interface GetCartProductsRequest {
//   type: typeof GET_CART_PRODUCTS_REQUEST;
//   payload: {};
// }
// interface GetCartProductsSuccess {
//   type: typeof GET_CART_PRODUCTS_SUCCESS;
//   payload: {
//     list: Product[];
//   };
// }
// interface GetCartProductsFail {
//   type: typeof GET_CART_PRODUCTS_FAIL;
//   payload: {
//     error: string;
//   };
// }

interface SubmitOrderRequest {
  type: typeof SUBMIT_ORDER_REQUEST;
  payload: {};
}

interface SubmitOrderSuccess {
  type: typeof SUBMIT_ORDER_SUCCESS;
  payload: {};
}

interface SubmitOrderFail {
  type: typeof SUBMIT_ORDER_FAIL;
  payload: { error: string };
}
interface ResetCartError {
  type: typeof RESET_CART_ERROR;
  payload: {};
}
interface ClearCartRequest {
  type: typeof CLEAR_CART_REQUEST;
  payload: {};
}

interface ClearCartSuccess {
  type: typeof CLEAR_CART_SUCCESS;
  payload: {};
}

interface ClearCartFail {
  type: typeof CLEAR_CART_FAIL;
  payload: {
    error: string;
  };
}

interface AddCartItemRequest {
  type: typeof ADD_CART_ITEM_REQUEST;
  payload: {
    id: string;
  };
}

interface AddCartItemSuccess {
  type: typeof ADD_CART_ITEM_SUCCESS;
  payload: {
    id: string;
    quantity: number;
    price: number | null;
    isLocalPickup: boolean;
  };
}

interface AddCartItemFail {
  type: typeof ADD_CART_ITEM_FAIL;
  payload: {
    id: string;
    error: string;
  };
}

interface DeleteCartItemRequest {
  type: typeof DELETE_CART_ITEM_REQUEST;
  payload: {
    id: string;
  };
}
interface DeleteCartItemSuccess {
  type: typeof DELETE_CART_ITEM_SUCCESS;
  payload: {
    id: string;
    price: number | null;
  };
}

interface DeleteCartItemFail {
  type: typeof DELETE_CART_ITEM_FAIL;
  payload: {
    id: string;
    error: string;
  };
}

interface UpdateCartItemRequest {
  type: typeof UPDATE_CART_ITEM_REQUEST;
  payload: {
    id: string;
  };
}

interface UpdateCartItemSuccess {
  type: typeof UPDATE_CART_ITEM_SUCCESS;
  payload: {
    id: string;
    newQuantity: number;
    price: number | null;
  };
}

interface UpdateCartItemFail {
  type: typeof UPDATE_CART_ITEM_FAIL;
  payload: {
    id: string;
    error: string;
  };
}

// addresses
// paypal
// creditCredits
// billing and shipping //
interface GetCartItemsRequest {
  type: typeof GET_CART_ITEMS_REQUEST;
  payload: {};
}

interface GetCartItemsSuccess {
  type: typeof GET_CART_ITEMS_SUCCESS;
  payload: {
    list: CartItem[];
  };
}

interface GetCartItemsFail {
  type: typeof GET_CART_ITEMS_FAIL;
  payload: {
    error: string;
  };
}

// interface GetProductRequest {
//   type: typeof GET_PRODUCT_REQUEST;
//   payload: {};
// }
// interface GetProductSuccess {
//   type: typeof GET_PRODUCT_SUCCESS;
//   payload: {
//     product: Product;
//   };
// }
// interface GetProductFail {
//   type: typeof GET_PRODUCT_FAIL;
//   payload: {
//     error: string;
//   };
// }

interface SetTotal {
  type: typeof SET_TOTAL;
  payload: {
    total: number;
  };
}

// shippmentIndex:number;
// shippmentPrice:number;

interface SetItemShippmentCompany {
  type: typeof SET_ITEM_SHIPPMENT;
  payload: {
    id: string;
    companyIndex: number;
    isLocalPickup: boolean;
  };
}

interface DeleteItemShippmentCompany {
  type: typeof DELETE_ITEM_SHIPPMENT;
  payload: {
    id: string;
  };
}

interface UpdateItemShippmentCompany {
  type: typeof UPDATE_ITEM_SHIPPMENT;
  payload: {
    id: string;
    companyIndex?: number;
    isLocalPickup?: boolean;
  };
}

export interface CartState {
  total: number;
  error: string;
  cart: CartItem[];
  products: Product[]; // we need to cache product data, legacy
  lastFetch: number; // legacy
  cartShippment: {
    productId: string;
    companyIndex: number;
    shippmentPrice?: number | null;
    isLocalPickup?: boolean;
  }[];
}

export type CartActionTypes =
  | ClearCartRequest
  | ClearCartSuccess
  | ClearCartFail
  | AddCartItemRequest
  | AddCartItemSuccess
  | AddCartItemFail
  | DeleteCartItemRequest
  | DeleteCartItemSuccess
  | DeleteCartItemFail
  | UpdateCartItemRequest
  | UpdateCartItemSuccess
  | UpdateCartItemFail
  | GetCartItemsRequest
  | GetCartItemsSuccess
  | GetCartItemsFail
  | SetItemShippmentCompany
  | DeleteItemShippmentCompany
  | UpdateItemShippmentCompany
  | SetTotal
  | ResetCartError
  | SubmitOrderRequest
  | SubmitOrderSuccess
  | SubmitOrderFail;

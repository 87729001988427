import React from 'react';
import { Typography, Link } from '@material-ui/core';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import useStyles from './styles';
import {
  useTheme, 
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery'; 

const AuthCover = ({ title, text }: { title: string; text: string }) => {
  const classes = useStyles();
  const theme = useTheme(); 
  const xsDown = useMediaQuery(theme.breakpoints.down('xs')); 

  return (
    <div
      className={classes.coverWrapper}
      style={{ position: xsDown ? 'unset' : 'relative' }} 
    >
      <img
        src={require('../../assets/signBackground.png')}
        width="100%"
        height="100%"
        alt="antiquesmart cover"
        style={{
          height: xsDown ? '40%' : '100%',
          width: xsDown ? '200%' : '100%',
        }} 
      />
      <Link
        to="/"
        variant="h4"
        component={RouterLink}
        className={clsx(classes.title, classes.ubuntuBoldWhite)}
      >
        <img
          src={require('../../assets/landing-page/logo.png')}
          alt="logo"
          style={{
            width: xsDown ? '140px' : '250px',
            height: xsDown ? '100px' : '180px',
          }}
        />
        {/* <Logo /> */}
        {/* Antiquesmart */}
      </Link>
      <div
        className={classes.midCoverTextWrapper}
        style={{ top: xsDown ? '18%' : '30%', fontFamily: 'Poppins' }} 
      >
        <Typography
          className={clsx(classes.ubuntuBoldWhite, classes.coverMidTitle)}
          component="h1"
          variant="h1"
          gutterBottom
          style={{fontSize: '40px'}}
        >
          {title}
        </Typography>
        {text && (
          <Typography
            className={clsx(classes.ubuntuBoldWhite)}
            style={{fontSize: '25px', fontFamily: 'Poppins'}}
            
            component="h1"
            variant="h1"
            gutterBottom
          >
            {text}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default AuthCover;

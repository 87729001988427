import firebase from 'firebase/app';

interface PostType {
  id?: string;
  content: string;
  images?: string[];
  likesCount?: number;
  commentsCount: number;
  createdAt?: firebase.firestore.Timestamp | Date;
  updatedAt?: firebase.firestore.Timestamp | Date;
  uid: string;
  userDisplayName: string;
  profilePicture: string;
  likedByIDs?: string[];
}

export default class Post implements PostType {
  id?: string;
  content: string;
  images?: string[];
  likesCount?: number;
  commentsCount: number;
  createdAt?: firebase.firestore.Timestamp | Date | null | any;
  updatedAt?: firebase.firestore.Timestamp | Date | null | any;
  uid: string;
  userDisplayName: string;
  profilePicture: string;
  likedByIDs?: string[];

  constructor(data?: PostType) {
    this.id = data?.id ?? '';
    this.content = data?.content ?? '';
    this.images = data?.images ?? [];
    this.likesCount = data?.likesCount ?? 0;
    this.commentsCount = data?.commentsCount ?? 0;
    this.createdAt = data?.createdAt ?? null;
    this.updatedAt = data?.updatedAt ?? null;
    this.uid = data?.uid ?? '';
    this.userDisplayName = data?.userDisplayName ?? '';
    this.profilePicture = data?.profilePicture ?? '';
    this.likedByIDs = data?.likedByIDs ?? [];
  }
}

export const postConverter: firebase.firestore.FirestoreDataConverter<Post> = {
  toFirestore: (post: Partial<Post>): firebase.firestore.DocumentData => {
    return {
      ...post,
    };
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ) => {
    const data = snapshot.data(options) as Post;
    return new Post({
      id: snapshot.id,
      ...(data as Post),
    });
  },
};

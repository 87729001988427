import {
  ReplyActionTypes,
  GET_REPLIES_FAIL,
  GET_REPLIES_REQUEST,
  GET_REPLIES_SUCCESS,
  ADD_REPLY_REQUEST,
  ADD_REPLY_SUCCESS,
  ADD_REPLY_FAIL,
  State,
} from '../types/reply';

const initState: State = {
  repliesByCommentId: {},
  error: '',
};

const reducer = (state = initState, action: ReplyActionTypes): State => {
  if (action.type === GET_REPLIES_REQUEST) {
    return state;
  } else if (action.type === GET_REPLIES_SUCCESS) {
    return {
      ...state,
      repliesByCommentId: {
        ...state.repliesByCommentId,
        [action.payload.commentId]: action.payload.repliesList,
      },
    };
  } else if (action.type === GET_REPLIES_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === ADD_REPLY_REQUEST) {
    return state;
  } else if (action.type === ADD_REPLY_SUCCESS) {
    const { commentId, reply } = action.payload;
    return {
      ...state,
      repliesByCommentId: {
        ...state.repliesByCommentId,
        [commentId]: [...(state.repliesByCommentId[commentId] || []), reply],
      },
      error: '',
    };
  } else if (action.type === ADD_REPLY_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  }

  return state;
};

export default reducer;

import firebase from 'firebase/app';
import { CartItem } from '../../models/[new]product';
import { ReviewIndex, Shop, SellerData } from '../../models/[new]user';
import moment from 'moment';
import { ConnectAccount } from '../../models/connect-account';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const SET_USER_REQUEST = 'SET_USER_REQUEST';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_USER_FAIL = 'SET_USER_FAIL';

export const SET_USERNAME_REQUEST = '';

export const SET_DEFAULT_BILLIN_ADDRESS_REQUEST = 'SET_DEFAULT_BILLING_ADDRESS';
export const SET_DEFAULT_BILLIN_ADDRESS_SUCCESS = 'SET_DEFAULT_BILLING_SUCCESS';
export const SET_DEFAULT_BILLIN_ADDRESS_FAIL = 'SET_DEFAULT_BILLING_FAIL';

export const SET_DEFAULT_SHIPPING_ADDRESS_REQUEST =
  'SET_DEFAULT_SHIPPING_ADDRESS_REQUEST';
export const SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS =
  'SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS';
export const SET_DEFAULT_SHIPPING_ADDRESS_FAIL =
  'SET_DEFAULT_SHIPPING_ADDRESS_FAIL';

export const SET_PROFILE_PICTURE_REQUEST = 'SET_PROFILE_PICTURE';
export const SET_PROFILE_PICTURE_SUCCESS = 'SET_PROFILE_PICTURE_SUCCESS';
export const SET_PROFILE_PICTURE_FAIL = 'SET_PROFILE_PICTURE_FAIL';

export const SET_SHOP_LOGO_REQUEST = 'SET_SHOP_LOGO';
export const SET_SHOP_LOGO_SUCCESS = 'SET_SHOP_LOGO_SUCCESS';
export const SET_SHOP_LOGO_FAIL = 'SET_SHOP_LOGO_FAIL';

export const FOLLOW_USER_REQUEST = 'FOLLOW_USER_REQUEST';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_FAIL = 'FOLLOW_USER_FAIL';

export const PUSH_TO_RECENTLY_VIEWED = 'PUSH_TO_RECENTLY_VIEWED';

interface PushToRecentlyViewed {
  type: typeof PUSH_TO_RECENTLY_VIEWED;
  payload: {
    id: string;
  };
}

interface GetUserAction {
  type: typeof GET_USER_REQUEST;
  payload: {};
}

interface GetUserSuccessAction {
  type: typeof GET_USER_SUCCESS;
  payload: {
    uid?: string;
    email?: string;
    userName?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    websiteLink?: string;
    gender?: string;
    state?: string;
    city?: string;
    phoneNumber?: string;
    profilePicture?: string;
    ssn?: string;
    bankAccount: null | ConnectAccount;
    registeredSince: firebase.firestore.Timestamp | null;
    completionRate?: number;
    defaultBillingAddress?: string;
    defaultShippingAddress?: string;
    dob?: firebase.firestore.Timestamp | Date | null;
    taxRate?: number;
    followersCount?: number;
    itemsSelling?: number;
    itemsSold?: number;
    itemsDraft?: number;
    isGlobalUser?: boolean;
    paymentType?: number;
    bag?: CartItem[]; // done
    wishList?: string[]; // done
    recentlyViewed?: string[];
    sellerReviewIndex?: ReviewIndex | null;
    userType?: string;
    shop?: Shop | null;
    disabled?: boolean;
    firstOne?: boolean;
    birthDate?: any | null;
    isDisabled?: boolean;
    sellerData?: SellerData | null;
  };
}

interface GetUserFailAction {
  type: typeof GET_USER_FAIL;
  payload: {
    error?: string;
  };
}

interface SetUserAction {
  type: typeof SET_USER_REQUEST;
  payload: {};
}

interface SetUserSuccessAction {
  type: typeof SET_USER_SUCCESS;
  payload: {
    firstName?: string;
    middleName?: string;
    websiteLink?: string;
    lastName?: string;
    userName?: string;
    gender?: string;
    phoneNo?: string;
    city?: string;
    state?: string;
    phoneNumber?: string;
    ssn?: string;
    bankAccount: null | ConnectAccount;
    registeredSince?: firebase.firestore.Timestamp | null;
    completionRate?: number;
    followersCount?: number;
    itemsSelling?: number;
    itemsSold?: number;
    itemsDraft?: number;
    isGlobalUser?: boolean;
    defaultBillingAddress?: string;
    defaultShippingAddress?: string;
    email?: string;
    dob?: firebase.firestore.Timestamp | Date | null;
    taxRate?: number;
    userType?: string;
    firstOne?: boolean;
    birthDate?: any | null;
  };
}

interface SetUserFailAction {
  type: typeof SET_USER_FAIL;
  payload: {
    error: string;
  };
}

interface SetProfilePictureAction {
  type: typeof SET_PROFILE_PICTURE_REQUEST;
  payload: {};
}

interface SetProfilePictureSuccessAction {
  type: typeof SET_PROFILE_PICTURE_SUCCESS;
  payload: {
    url: string;
  };
}
interface SetProfilePictureFailAction {
  type: typeof SET_PROFILE_PICTURE_FAIL;
  payload: {
    error?: string;
  };
}

interface SetShopLogoAction {
  type: typeof SET_SHOP_LOGO_REQUEST;
  payload: {};
}

interface SetShopLogoSuccessAction {
  type: typeof SET_SHOP_LOGO_SUCCESS;
  payload: {
    url: string;
  };
}
interface SetShopLogoFailAction {
  type: typeof SET_SHOP_LOGO_FAIL;
  payload: {
    error?: string;
  };
}

interface FollowUserRequestAction {
  type: typeof FOLLOW_USER_REQUEST;
  payload: {
    uid: string;
  };
}
interface FollowUserSuccessAction {
  type: typeof FOLLOW_USER_SUCCESS;
  payload: {
    uid: string;
  };
}

interface FollowUserFailAction {
  type: typeof FOLLOW_USER_FAIL;
  payload: {
    error?: string;
  };
}
export class BirthDate {
  day: number | null = null;
  month: number | null = null;
  year: number | null = null;

  get date() {
    if (this.day !== null && this.month !== null && this.year !== null) {
      return new Date(this.year, this.month - 1, this.day);
    } else return null;
  }

  get formattedDate() {
    if (this.day !== null && this.month !== null && this.year !== null)
      return moment(this.date).format('MMM DD, YYYY');
    else return '';
  }

  get inputDate() {
    if (this.day !== null && this.month !== null && this.year !== null)
      return moment(this.date).format('YYYY-MM-DD');
    else return '';
  }

  constructor(data?: Date) {
    this.day = data?.getDay() ?? null;
    this.month = data?.getMonth() ?? null;
    this.year = data?.getFullYear() ?? null;
  }

  toJson(): BirthDate {
    return Object.assign({}, this);
  }
}

export interface UserState {
  error: string;
  uid: string;
  email: string;
  userName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  websiteLink: string;
  gender: string;
  state: string;
  city: string;
  phoneNumber: string;
  ssn: string;
  registeredSince: firebase.firestore.Timestamp | Date | null;
  completionRate: number;
  defaultBillingAddress: string;
  defaultShippingAddress: string;
  formatedDob: BirthDate | null;
  dob: firebase.firestore.Timestamp | null;
  taxRate: number;
  followersCount: number;
  itemsSelling: number;
  itemsSold: number;
  paymentType: number;
  itemsDraft?: number;
  isGlobalUser?: boolean;
  profilePicture: {
    url: string;
    error: string;
  };
  bankAccount: null | ConnectAccount;
  wishList: {
    list: string[];
    error: string;
  };
  recentlyViewed: string[];
  sellerReviewIndex?: ReviewIndex | null;
  userType: string;
  shop: Shop | null;
  firstOne: boolean;
  birthDate: any | null;
  isDisabled: boolean;
  sellerData: SellerData | null;
}

export type UserActionTypes =
  | GetUserAction
  | GetUserSuccessAction
  | GetUserFailAction
  | SetUserAction
  | SetUserSuccessAction
  | SetUserFailAction
  | SetProfilePictureAction
  | SetProfilePictureSuccessAction
  | SetProfilePictureFailAction
  | FollowUserRequestAction
  | FollowUserSuccessAction
  | FollowUserFailAction
  | PushToRecentlyViewed
  | SetShopLogoAction
  | SetShopLogoSuccessAction
  | SetShopLogoFailAction;

import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Divider,
  TextField,
  IconButton,
  Link,
  useTheme, 
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import firebase from '../../../utils/firebase-utils';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AuthCover from '../../AuthCover';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import comonSnackOpts from '../../../utils/snackbar';
import useStyles from './styles';
import GoogleLoginBtn from '../../../components/buttons/socialBtns/GoogleLoginBtn';
import AppleLoginBtn from '../../../components/buttons/socialBtns/AppleLoginBtn';
import validation from './validation';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { loginThunk } from '../../../redux/actions/auth';
import { useTypedSelector } from '../../../redux/store';
import CustomButton from '../../../components/buttons/Button';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import useMediaQuery from '@material-ui/core/useMediaQuery'; 
import { Helmet } from 'react-helmet';
interface FormValues {
  email: string;
  password: string;
}

const Login = () => {
  const classes = useStyles();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const loginLoader = useTypedSelector(state => state.UI.loaders.LOGIN);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [curUser, setCurUser] = useState();

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    let userDisabled: any = [];

    await firebase
      .firestore()
      .collection('private_users')
      .where('email', '==', values.email)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          userDisabled.push(doc.data());
        });
        setCurUser(userDisabled);
      });

    if (userDisabled?.length ===  0) {
      enqueueSnackbar('Please enter a valid email and password!', {
        variant: 'error',
        ...comonSnackOpts,
      });
    } else if (userDisabled[0].isDisabled ===  true) {
      enqueueSnackbar('This user is disabled!', {
        variant: 'error',
        ...comonSnackOpts,
      });
    } else {
      dispatch(
        loginThunk({
          email: values.email,
          password: values.password,
          formikHelpers: helpers,
        }),
      );
    }

    /*    if (values.rememberMe) await remeberUser();
    else await forgetUser();*/
    // dispatch(
    //   loginThunk({
    //     email: values.email,
    //     password: values.password,
    //     formikHelpers: helpers,
    //   }),
    // );
  };

  const theme = useTheme(); 
  const xsDown = useMediaQuery(theme.breakpoints.down('xs')); 

  function getOS() {
    let userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      iosPlatforms = /(iphone|ipad|ipod)/i,
      os = null;

    if (macosPlatforms?.test(userAgent)) {
      os = 'macos';
    } else if (iosPlatforms?.test(userAgent)) {
      os = 'ios';
    }
    return os;
  }

  const schemaMarkup = { 
    "@context": "https://schema.org",   
    "@type": "WebPage",     
    "name": "Login to Antiquesmart", 
    "description": "Login to Antiquesmart and continue your search for unique and antique treasures. Discover a world of timeless collectibles.",
    "url": "https://antiquesmart.com/login",
    "publisher": {
      "@type": "Organization",
      "name": "Antiquesmart",
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "telephone": "(708) 807-0018",
          "contactType": "customer support"
        },
        {
          "@type": "ContactPoint",
          "email": "help@antiquesmart.com",
          "contactType": "customer support"
        }
      ],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "38355 Shagbark Lane Wadsworth",
        "addressLocality": "IL",
        "addressRegion": "IL",
        "postalCode": "60083",
        "addressCountry": "US"
      }
    },
    "sameAs": [
      "https://www.facebook.com/profile.php?id=100063778156772&mibextid=9R9pXO",
      "https://instagram.com/antiquesmart_app?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
    ]
  };

  return (
    <section>
     <Helmet>
        <title>Login to Antiquesmart | Discover Antique Treasures</title>
        <meta
          name="description"
          content="Login to Antiquesmart and continue your search for unique and antique treasures. Discover a world of timeless collectibles."
        />
        <link rel="canonical" href="https://antiquesmart.com/login" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      {forgotPassword && (
        <ForgotPasswordDialog handleClose={() => setForgotPassword(false)} />
      )}
      <Grid container>
        <Grid
          item
          container
          xs={6}
          style={{ height: xsDown ? '280px' : 'unset' }} 
        >
          <AuthCover
            title="Welcome back!"
            text="Log in to your Antiquesmart account."
          />
        </Grid>
        <Grid item container xs className={classes.formGrid}>
          <Grid item xs={12}>
            <Typography
              className={classes.formHeader}
              component="h2"
              variant="h5"
            >
              Sign In Using
            </Typography>
          </Grid>
          <Grid
            className={classes.socialLoginWrapper}
            item
            container
            alignItems="center"
            justify="space-around"
            xs={12}
            style={{ transform: xsDown ? 'scale(0.84)' : 'scale(1)' }} 
          >
            <Grid item>
              <GoogleLoginBtn />
            </Grid>
            {/* <Grid item>
              <FacebookLoginBtn />
            </Grid> */}
            {/* {(getOS() ===  'macos' || getOS() ===  'ios') && ( */}
            <Grid item>
              <AppleLoginBtn />
            </Grid>
            {/* )} */}
          </Grid>
          <Grid
            className={classes.orWrapper}
            item
            justify="center"
            alignItems="center"
            container
            xs={12}
          >
            <Grid item xs={5}>
              <Divider variant="middle" />
            </Grid>
            <Grid item>
              <Typography
                className={classes.orText}
                component="p"
                variant="subtitle1"
              >
                OR
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Divider variant="middle" />
            </Grid>
          </Grid>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={validation}
            onSubmit={handleSubmit}
            validateOnChange={false}
          >
            {({ errors, touched, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className={classes.muiGridLimit3}>
                  <Grid
                    className={classes.formGridsContainer}
                    spacing={3}
                    container
                  >
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        as={TextField}
                        type="email"
                        color="secondary"
                        variant="outlined"
                        name="email"
                        label="Email *"
                        error={touched.email && errors.email && true}
                        helperText={touched.email && errors.email}
                        InputProps={{
                          endAdornment: <MailOutlineIcon color="secondary" />,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        as={TextField}
                        color="secondary"
                        variant="outlined"
                        name="password"
                        label="Password *"
                        type={showPassword ? 'text' : 'password'}
                        error={touched.password && errors.password && true}
                        helperText={touched.password && errors.password}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              color="secondary"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <div
                        style={{ fontSize: '15px', display: 'inline-block' }}
                      >
                        <Typography style={{ display: 'inline-block' }}>
                          When you register, you are agreeing to our
                        </Typography>
                        <Link
                          href="/privacy-policy"
                          style={{
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                            fontFamily: 'Poppins, sans-serif',
                          }}
                        >
                          {'  '}Privacy Policy,
                        </Link>
                        <Link
                          href="/terms-of-service"
                          style={{
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                            fontFamily: 'Poppins, sans-serif',
                          }}
                        >
                          {' '}
                          Terms of Services,
                        </Link>
                        <Link
                          href="/eula"
                          style={{
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                            fontFamily: 'Poppins, sans-serif',
                          }}
                        >
                          {' '}
                          Eula
                        </Link>
                      </div>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="flex-end"
                      alignItems="center"
                      xs={12}
                    >
                      <Link
                        color="secondary"
                        component={RouterLink}
                        variant="subtitle1"
                        onClick={() => setForgotPassword(true)}
                        to="#"
                      >
                        Forgot Password?
                      </Link>
                    </Grid>
                    <Grid item container justify="flex-end" xs={12}>
                      <CustomButton
                        loading={loginLoader}
                        disabled={loginLoader}
                        size="large"
                        startIcon={<ArrowRightAltIcon />}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Sign In
                      </CustomButton>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            )}
          </Formik>
          <Typography
            className={classes.haveAccountText}
            component="p"
            variant="subtitle2"
          >
            Don't have an account?{' '}
            <Link
              color="secondary"
              component={RouterLink}
              variant="subtitle1"
              to="/signup"
            >
              SIGN UP
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </section>
  );
};
export default Login;

import { commentsC, repliesC, postsC } from '../../utils/firebase-utils';
import Reply, { replyConverter } from '../../models/[new]reply';
import { AppThunk } from '../store';
import { Something_Went_Wrong } from '../../utils/constants';
import {
  ReplyActionTypes,
  GET_REPLIES_REQUEST,
  GET_REPLIES_SUCCESS,
  GET_REPLIES_FAIL,
  ADD_REPLY_REQUEST,
  ADD_REPLY_SUCCESS,
  ADD_REPLY_FAIL,
} from '../types/reply';
import firebase from 'firebase';
import { commentConverter } from '../../models/[new]comment';
import { postConverter } from '../../models/[new]post';

// Get All Replies
export const getRepliesRequest = (): ReplyActionTypes => {
  return { type: GET_REPLIES_REQUEST, payload: {} };
};

export const getRepliesSuccess = ({
  repliesList,
  commentId,
}: {
  repliesList: Reply[];
  commentId: string;
}): ReplyActionTypes => {
  return {
    type: GET_REPLIES_SUCCESS,
    payload: {
      repliesList,
      commentId,
    },
  };
};
export const getRepliesFail = ({
  error,
}: {
  error: string;
}): ReplyActionTypes => {
  return {
    type: GET_REPLIES_FAIL,
    payload: {
      error,
    },
  };
};

export const getRepliesThunk = (
  postID: string,
  commentId?: string,
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(getRepliesRequest());
    try {
      const repliesRef = await repliesC(postID, commentId)
        .withConverter(replyConverter)
        .orderBy('createdAt', 'asc')
        .get();
      const repliesList = repliesRef.docs?.map(doc => doc?.data() as Reply);

      dispatch(getRepliesSuccess({ repliesList, commentId: commentId ?? '' }));
    } catch (e) {
      console.log(e);
      dispatch(getRepliesFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};

// Add New Reply
export const addReplyRequest = (): ReplyActionTypes => {
  return { type: ADD_REPLY_REQUEST, payload: {} };
};

export const addReplySuccess = ({
  reply,
  commentId,
}: {
  reply: Reply;
  commentId: string;
}): ReplyActionTypes => {
  return {
    type: ADD_REPLY_SUCCESS,
    payload: {
      reply,
      commentId,
    },
  };
};
export const addReplyFail = ({
  error,
}: {
  error: string;
}): ReplyActionTypes => {
  return {
    type: ADD_REPLY_FAIL,
    payload: {
      error,
    },
  };
};

export const addReplyThunk = ({
  postID,
  commentId,
  content,
  userDisplayName,
  profilePicture,
}: any): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();
    dispatch(addReplyRequest());
    try {
      if (content) {
        const emptyDoc = await repliesC(postID, commentId).doc();
        const createdAt = firebase.firestore.FieldValue.serverTimestamp();
        await repliesC(postID, commentId)
          .withConverter(replyConverter)
          .doc(emptyDoc.id)
          .set(
            {
              content,
              uid,
              userDisplayName,
              profilePicture,
              id: emptyDoc.id,
              createdAt,
            },
            { merge: true },
          );

        const increment = firebase.firestore.FieldValue.increment(1);

        await commentsC(postID)
          .withConverter(commentConverter)
          .doc(commentId)
          .set(
            {
              repliesCount: increment as unknown as number,
            },
            { merge: true },
          );

        await postsC
          .withConverter(postConverter)
          .doc(postID)
          .set(
            {
              commentsCount: increment as unknown as number,
            },
            { merge: true },
          );

        const reply = {
          id: emptyDoc.id,
          content,
          uid,
          userDisplayName,
          profilePicture,
          postID,
          commentId,
        };

        dispatch(addReplySuccess({ commentId, reply }));
      } else {
        throw new Error('Content is undefined');
      }
    } catch (e) {
      console.log(e);
      dispatch(addReplyFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};

import Reply from '../../models/[new]reply';

export const GET_REPLIES_REQUEST = 'GET_REPLIES_REQUEST';
export const GET_REPLIES_SUCCESS = 'GET_REPLIES_SUCCESS';
export const GET_REPLIES_FAIL = 'GET_REPLIES_FAIL';

export const ADD_REPLY_REQUEST = 'ADD_REPLY_REQUEST';
export const ADD_REPLY_SUCCESS = 'ADD_REPLY_SUCCESS';
export const ADD_REPLY_FAIL = 'ADD_REPLY_FAIL';

// Get All Replies
interface GetRepliesRequest {
  type: typeof GET_REPLIES_REQUEST;
  payload: {};
}

interface GetRepliesSuccess {
  type: typeof GET_REPLIES_SUCCESS;
  payload: {
    repliesList: Reply[];
    commentId: string;
  };
}

interface GetRepliesFail {
  type: typeof GET_REPLIES_FAIL;
  payload: {
    error: string;
  };
}

// New Reply
interface AddReplyRequest {
  type: typeof ADD_REPLY_REQUEST;
  payload: {};
}

interface AddReplySuccess {
  type: typeof ADD_REPLY_SUCCESS;
  payload: {
    commentId: string;
    reply: Reply;
  };
}

interface AddReplyFail {
  type: typeof ADD_REPLY_FAIL;
  payload: {
    error: string;
  };
}

export type ReplyActionTypes =
  | GetRepliesRequest
  | GetRepliesSuccess
  | GetRepliesFail
  | AddReplyRequest
  | AddReplySuccess
  | AddReplyFail;

export interface State {
  repliesByCommentId: { [commentId: string]: Reply[] };
  error: string;
}

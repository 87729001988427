import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { FcGoogle } from 'react-icons/fc';
import { useDispatch } from 'react-redux';

import CustomButton, { Props as CustomButtonProps } from '../Button';
import { loginWithGoogleThunk } from '../../../redux/actions/auth';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: '#fff',
      width: '160px',
      height: '48px',
    },
  }),
);

const GoogleLoginBtn = (props: CustomButtonProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <CustomButton
      className={classes.root}
      startIcon={<FcGoogle />}
      variant="contained"
      {...props}
      onClick={() => dispatch(loginWithGoogleThunk())}
    >
      google
    </CustomButton>
  );
};

export default GoogleLoginBtn;

import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup.string().email().required('Please enter your Email'),
  password: yup
    .string()
    .min(
      6,
      'The accepted password should be more than 6 characters and numbers',
    )
    .required('Please enter your Password'),
});

export default schema;

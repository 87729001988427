import { AppThunk } from '../store';
import { FormikHelpers } from 'formik';
import firebase from 'firebase/app';
import axios from 'axios';
import { baseURL } from '../../utils/firebase-utils';
import {
  AuthActionTypes,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  RESET_AUTH_ERRORS,
  SIGNUP_FAIL,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  VERVIY_EMAIL,
} from '../types/auth';

import { getUserThunk, setUserThunk } from './user';

import {
  auth,
  privateUsersC,
  SignInWithFacebook,
  SignInWithGoogle,
  SignInWithApple,
  userNamesC,
  usersC,
} from '../../utils/firebase-utils';

import firebaseAuthErrs from '../../utils/firebaseAuthErrs';
import { userConverter } from '../../models/[new]user';
import { privateUserConverter } from '../../models/private-user';
import { getAddressesThunk } from './addresses';
import { getCardsThunk, getPaypalThunk } from './payment';

export const loginRequest = (): AuthActionTypes => {
  return {
    type: LOGIN_REQUEST,
    payload: {},
  };
};

export const resetAuthErrors = (): AuthActionTypes => {
  return {
    type: RESET_AUTH_ERRORS,
    payload: {},
  };
};

export const loginSuccess = ({
  uid,
  email,
  emailVerified,
}: {
  uid: string;
  email: string;
  emailVerified: boolean;
}): AuthActionTypes => {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      uid,
      email,
      emailVerified,
    },
  };
};

export const loginFail = ({
  email,
  password,
  user,
}: {
  email: string;
  password: string;
  user: string;
}) => {
  return {
    type: LOGIN_FAIL,
    payload: {
      email,
      password,
      user,
    },
  };
};

export const logoutUser = (): AuthActionTypes => {
  return {
    type: LOGOUT_USER,
    payload: {},
  };
};

export const verfiyEmail = (verfied: boolean): AuthActionTypes => {
  return {
    type: VERVIY_EMAIL,
    payload: {
      verfied,
    },
  };
};

export const signupRequest = (): AuthActionTypes => {
  return {
    type: SIGNUP_REQUEST,
    payload: {},
  };
};

export const signupSuccess = ({
  uid,
  username,
}: {
  username: string;
  uid: string;
}): AuthActionTypes => {
  return {
    type: SIGNUP_SUCCESS,
    payload: { uid, username },
  };
};

export const signupFail = ({
  email = '',
  password = '',
  username = '',
}: {
  email?: string;
  password?: string;
  username?: string;
}): AuthActionTypes => {
  return {
    type: SIGNUP_FAIL,
    payload: {
      error: { email, password, user: username },
    },
  };
};

export const signupThunk = ({
  firstName,
  middleName,
  lastName,
  email,
  password,
  userName,
  //profilePicture,
  formikHelpers,
  
}: {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  password: string;
  userName: string;
  //profilePicture:string;
  formikHelpers?: FormikHelpers<any>;
}): AppThunk => {
  return async dispatch => {
    try {
      dispatch(signupRequest());
      const { exists } = await userNamesC.doc(userName).get();
      if (exists) {
        let msg = 'Username is taken, please try another';
        formikHelpers?.setErrors({ userName: msg });
        return dispatch(
          signupFail({
            username: msg,
          }),
        );
      }
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password,
      );
      if (user) {
        await Promise.all([
          userNamesC.doc(userName).set({
            userName,
          }),
          usersC
            .withConverter(userConverter)
            .doc(user.uid)
            .set({
              firstName,
              middleName,
              lastName,
              userName,
              profilePicture: '',
              registeredSince:
                firebase.firestore.FieldValue.serverTimestamp() as any,
              uid: user.uid,
              userType: 'buyer',
              shop: null,
              _geoloc: null,
              paymentType: -1,
              isGlobalUser: false,
              sellerReviewIndex: {
                totalAverage: 0.0,
                totalCount: 0,
                oneCount: 0,
                twoCount: 0,
                threeCount: 0,
                fourCount: 0,
                fiveCount: 0,
              } as any,
              followersCount: 0,
              firstOne: false,
            }),
          privateUsersC.withConverter(privateUserConverter).doc(user.uid).set({
            email,
            uid: user.uid,
            defaultBillingAddress: '',
            defaultShippingAddress: '',
            bag: [],
            gender: '',
            phoneNumber: '',
            ssn: '',
            wishList: [],
            dob: null,
            birthDate: null,
            isDisabled: false,
          }),
          // user.sendEmailVerification(),
          axios
            .post(`${baseURL}/verificationEmail`, {
              email: email,
            })
            .then(res => console.log('verification email has been sent!'))
            .catch(err => console.log(err)),
        ]);

        dispatch(signupSuccess({ uid: user.uid, username: userName }));
      }
    } catch (e) {
      const { email, password, user } = firebaseAuthErrs(e);
      dispatch(signupFail(firebaseAuthErrs(e)));
      formikHelpers &&
        formikHelpers.setErrors({
          email: email,
          password,
          user,
        });
      setTimeout(() => {
        formikHelpers && formikHelpers.setErrors({});
      }, 3000);
    }
  };
};

export const loginThunk = ({
  email,
  password,
  formikHelpers,
}: {
  email: string;
  password: string;
  formikHelpers?: FormikHelpers<any>;
}): AppThunk => {
  return async dispatch => {
    try {
      dispatch(loginRequest());
      await auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      const authErrors = firebaseAuthErrs(e);
      const { email, password, user } = authErrors;
      formikHelpers &&
        formikHelpers.setErrors({
          email: email || user,
          password,
        });

      dispatch(loginFail(authErrors));
    }
  };
};

export const logoutThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();
    /*    const token=await messagingFCM();
        if (uid && token) {
          await popFcmToken({
            uid,
            token,
          });
        }*/
    await auth.signOut();
    dispatch(logoutUser());
  };
};

export const verfiyAuthThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    auth.onAuthStateChanged(async user => {
      if (user && user?.email) {
        //const token = await generateUserToken();
        // const token=await messagingFCM();
        const {
          auth: { uid },
        } = getState();

        if (!uid) {
          const userExists = await (await usersC.doc(user?.uid).get()).exists;
          if (!userExists) {
            return;
          }
          fillUserDetails(user, dispatch);
        }
      } else {
        dispatch(logoutThunk());
      }
    });
  };
};

const fillUserDetails = async (user: firebase.User, dispatch: any) => {
  dispatch(
    loginSuccess({
      email: user?.email || '',
      emailVerified: user?.emailVerified,
      uid: user?.uid,
    }),
  );
  //await pushFcmToken({ uid: user.uid, token });
  try {
    await dispatch(getUserThunk());
    await dispatch(getAddressesThunk());
    await dispatch(getCardsThunk());
  } catch (err) {
    console.log('err : ', err);
  }
};

// the success action is dispatched from the verfiy Auth thunk
export const loginWithGoogleThunk = (): AppThunk => {
  return async dispatch => {
    dispatch(loginRequest());
    try {
      const { user } = await SignInWithGoogle();
      if (!user) {
        // dispatch(loginFail({}));
        return;
      }

      //const token = await generateUserToken();
      /*
            const token=await messagingFCM();
            pushFcmToken({ uid: user.uid, token });
      */

      const userExists = await (await usersC.doc(user.uid).get()).exists;
      if (userExists) {
        return;
      }

      // new User
      const { displayName, photoURL, email } = user;
      let firstName = '',
        lastName = '';
      if (displayName) {
        const names = displayName?.split(' ');
        firstName = names[0];
        lastName = names[1];
      }

      await Promise.all([
        usersC
          .withConverter(userConverter)
          .doc(user.uid)
          .set({
            firstName,
            lastName,
            registeredSince:
              firebase.firestore.FieldValue.serverTimestamp() as any,
            uid: user.uid,
            profilePicture: photoURL ?? '',
            userType: 'buyer',
            shop: null,
            _geoloc: null,
            sellerReviewIndex: {
              totalAverage: 0.0,
              totalCount: 0,
              oneCount: 0,
              twoCount: 0,
              threeCount: 0,
              fourCount: 0,
              fiveCount: 0,
            } as any,
            firstOne: false,
          }),
        privateUsersC
          .withConverter(privateUserConverter)
          .doc(user.uid)
          .set({
            email: email as string,
            uid: user.uid,
            defaultBillingAddress: '',
            defaultShippingAddress: '',
            bag: [],
            gender: '',
            phoneNumber: '',
            ssn: '',
            wishList: [],
            dob: null,
            birthDate: null,
          }),
      ]);
      if (user && user?.email) {
        fillUserDetails(user, dispatch);
      }
    } catch (e) {
      dispatch(loginFail(firebaseAuthErrs(e?.code)));
    }
  };
};

export const loginWithFacebookThunk = (): AppThunk => {
  return async dispatch => {
    dispatch(loginRequest());

    try {
      const { user } = await SignInWithFacebook();

      if (user) {
        //const token = await generateUserToken();
        /*        const token=await messagingFCM();
                pushFcmToken({ uid: user.uid, token });*/
        dispatch(
          loginSuccess({
            uid: user.uid,
            email: user.email ?? '',
            emailVerified: user.emailVerified,
          }),
        );
      }
    } catch (e) {
      dispatch(loginFail(firebaseAuthErrs(e?.code)));
    }
  };
};

export const loginWithAppleThunk = (): AppThunk => {
  return async dispatch => {
    dispatch(loginRequest());
    try {
      const { user } = await SignInWithApple();
      // console.log('result', result);

      if (!user) {
        // dispatch(loginFail({}));
        return;
      }
      //const token = await generateUserToken();
      /*
            const token=await messagingFCM();
            pushFcmToken({ uid: user.uid, token });
      */
      const userExists = await (await usersC.doc(user.uid).get()).exists;
      if (userExists) {
        return;
      }

      // new User
      const { displayName, email } = user;
      let firstName = '',
        lastName = '';
      if (displayName) {
        const names = displayName?.split(' ');
        firstName = names[0];
        lastName = names[1];
      }

      await Promise.all([
        usersC
          .withConverter(userConverter)
          .doc(user?.uid)
          .set({
            firstName,
            lastName,
            registeredSince:
              firebase.firestore.FieldValue.serverTimestamp() as any,
            uid: user?.uid,
            profilePicture: '',
            userType: 'buyer',
            shop: null,
            _geoloc: null,
            sellerReviewIndex: {
              totalAverage: 0.0,
              totalCount: 0,
              oneCount: 0,
              twoCount: 0,
              threeCount: 0,
              fourCount: 0,
              fiveCount: 0,
            } as any,
            firstOne: false,
          }),
        privateUsersC
          .withConverter(privateUserConverter)
          .doc(user?.uid)
          .set(
            {
              email: email as string,
              uid: user?.uid,
              defaultBillingAddress: '',
              defaultShippingAddress: '',
              bag: [],
              gender: '',
              phoneNumber: '',
              ssn: '',
              wishList: [],
              dob: null,
              birthDate: null,
            },
            { merge: true },
          ),
      ]);
      if (user && user?.email) {
        fillUserDetails(user, dispatch);
      }
    } catch (e) {
      console.log('e', e);
      dispatch(loginFail(firebaseAuthErrs(e?.code)));
    }
  };
};

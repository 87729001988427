import { title } from 'process';
import firebase from 'firebase/app';
import Address from './[new]address';

export enum Dates {
  CUSTOM_SELECT,
  RECENTLY,
  VINTAGE,
  FROM_2020,
  FROM_2010,
  FROM_2001,
  FROM_2000,
  FROM_1990s,
  FROM_1980s,
  FROM_1970s,
  FROM_1960s,
  FROM_1950s,
  FROM_1940s,
  FROM_1930s,
  FROM_1920s,
  FROM_1910s,
  FROM_1900s,
}

export interface CartItem {
  id: string;
  quantity: number;
  isLocalPickup: boolean;
  companyIndex: number;
}

export interface Geoloc {
  lat: number;
  lng: number;
}

export interface ShipmentCompany {
  name: string;
  price: number;
}

export interface ProductType {
  id: string;
  title: string;
  uid: string;
  quantity: number;
  year: number;
  description: string;
  category: string[];
  images: string[];
  custom: number;
  shippingDetails: string;
  condition: string;
  isCancelled: boolean;
  isSelling: boolean;
  isSold: boolean;
  isReviews: boolean;
  isDraft: boolean;
  isPickup: boolean;
  sellerName: string;
  userName: string;
  tags: string[];
  _geoloc: Geoloc;
  paymentIntent: string;
  createAt: firebase.firestore.Timestamp;
  address: Address;
  shipmentCompanies: ShipmentCompany[];
  price: number | null;
  isCallForPrice: boolean;
  isDeleted: boolean;
  paymentType: number;
  highlighted: boolean;
  slug_title: string;
}

export class Product {
  date: Dates;
  userName: string;
  id: string;
  title: string;
  uid: string;
  sellerName: string;
  quantity: number;
  taxRate: number;
  description: string;
  category: string[];
  images: string[];
  isSelling: boolean;
  isSold: boolean;
  isLocalPickup: boolean;
  isDeleted: boolean;
  isDraft: boolean;
  createAt: firebase.firestore.Timestamp;
  tags: string[];
  price: number | null;
  isCallForPrice: boolean;
  _geoloc: Geoloc;
  shipmentCompanies: ShipmentCompany[];
  address: Address;
  paymentType: number;
  highlight: boolean;
  slug_title: string;

  constructor(data?: { [field: string]: any }) {
    this.id = data?.id ?? '';
    this.date = data?.date ?? '';
    this.taxRate = data?.taxRate ?? null;
    this.uid = data?.uid ?? '';
    this.title = data?.title ?? '';
    this.slug_title = data?.slug_title ?? '';
    this.quantity = data?.quantity ?? '';
    this.description = data?.description ?? '';
    this.category = data?.category ?? [];
    this.images = data?.images ?? [];
    this.isSelling = data?.isSelling ?? true;
    this.isSold = data?.isSold ?? false;
    this.isDraft = data?.isDraft ?? false;
    this.createAt = data?.createAt;
    this.tags = data?.tags ?? [];
    // this.price = data?.price ?? '';
    // this.price = data?.price ?? null;
    this.price = (data?.price === null || data?.price === undefined) ? null : data?.price === '' ? '' : data?.price;

    this.isCallForPrice = data?.isCallForPrice ?? false;

    this.paymentType = data?.paymentType ?? 0;

    this.sellerName = data?.sellerName ?? '';
    this.isDeleted = data?.isDeleted ?? false;
    this._geoloc = data?._geoloc ?? null;
    this.userName = data?.userName ?? '';
    this.address = data?.address ?? null;
    this.isLocalPickup = data?.isLocalPickup ?? false;
    this.shipmentCompanies = data?.shipmentCompanies ?? [];
    this.highlight = data?.highlight ?? false;
  }

  toJson(): Product {
    return Object.assign({}, this);
  }
}

export const productConverter: firebase.firestore.FirestoreDataConverter<
  Partial<ProductType>
> = {
  toFirestore: (data: ProductType): firebase.firestore.DocumentData => {
    return {
      ...data,
    };
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ) => {
    const data = snapshot.data(options);
    return new Product({
      id: snapshot.id,
      ...data,
    } as ProductType);
  },
};

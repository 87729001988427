import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container } from '@material-ui/core';


const BlogDetailPage = () => {

const [blogDetails, setBlogDetails] = useState(null);


   const { id } = useParams();

   useEffect(() => {
    axios
      .get(`https://cms.zetaton.dev/api/Blog-details?filters[blogName][$eq]=${id}`)
      .then(response => {
        let blogData = response.data.data[0];
        
        if (blogData && blogData.attributes && blogData.attributes.Editor) {
          let modifiedEditor = blogData.attributes.Editor.replace(
            /(\w+ \d{1,2}, \d{4})/g, 
            (match) => {
              return formatDate(match);
            }
          );

          blogData.attributes.Editor = modifiedEditor;
        }

        setBlogDetails(blogData);
      })
      .catch(error => console.error('Error fetching blog details:', error));
  }, [id]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
  };

  // useEffect(() => {
  //   axios.get(`https://cms.zetaton.dev/api/Blog-details?filters[blogName][$eq]=${id}`)
  //     .then(response => {
  //       setBlogDetails(response.data.data[0]);
  //       console.log(response.data.data[0]?.attributes?.Editor, 'reesssss&&&&&&&')
  //     })
  //     .catch(error => console.error('Error fetching blog details:', error));
  // }, [id]);

  return (
    <div>
         <Container style={{ marginTop:'10px' }}>
      {blogDetails ? (
       
        // <p>osks</p>
        <div dangerouslySetInnerHTML={{ __html: blogDetails.attributes?.Editor }} />
      ) : (
        <p>Loading...</p>
      )}
      </Container>
    </div>

  );
};

export default BlogDetailPage;

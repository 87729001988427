export const SET_CHECKOUT_SHIPPING_ADDRESS_ID =
  'SET_CHECKOUT_SHIPPING_ADDRESS_ID';
export const SET_CHECKOUT_BILLING_ADDRESS_ID =
  'SET_CHECKOUT_BILLING_ADDRESS_ID';
export const SET_CHECKOUT_PAYMENT_METHOD = 'SET_CHECKOUT_PAYMENT_METHOD';

interface SetShippingAddressId {
  type: typeof SET_CHECKOUT_SHIPPING_ADDRESS_ID;
  payload: {
    id: string;
  };
}

interface SetBillingAddressId {
  type: typeof SET_CHECKOUT_BILLING_ADDRESS_ID;
  payload: {
    id: string;
  };
}
interface SetPaymentMethod {
  type: typeof SET_CHECKOUT_PAYMENT_METHOD;
  payload: {
    type: 'CARD' | 'PAYPAL';
    token: string;
    value:string;
  };
}

export type ActionTypes =
  | SetShippingAddressId
  | SetBillingAddressId
  | SetPaymentMethod;

export interface StateType {
  method: 'CARD' | 'PAYPAL' | '';
  shippingAddressId: string;
  billingAddressId: string;
  paymentToken: string;
  paymentValue:string;
}

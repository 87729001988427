import {
  PostActionTypes,
  SEARCH_POSTS_REQUEST,
  SEARCH_POSTS_SUCCESS,
  SEARCH_POSTS_FAIL,
  GET_POSTS_REQUEST,
  GET_POSTS_SUCCESS,
  GET_POSTS_FAIL,
  ADD_POST_REQUEST,
  ADD_POST_SUCCESS,
  ADD_POST_FAIL,
  UPDATE_POST_REQUEST,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_FAIL,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAIL,
  LIKE_POST_FAIL,
  LIKE_POST_REQUEST,
  LIKE_POST_SUCCESS,
  DISLIKE_POST_FAIL,
  DISLIKE_POST_REQUEST,
  DISLIKE_POST_SUCCESS,
  GET_ONE_POST_REQUEST,
  GET_ONE_POST_SUCCESS,
  GET_ONE_POST_FAIL,
  UPDATE_COMMENTS_COUNT,
  State,
} from '../types/posts';

const initState: State = {
  postsList: [],
  singlePost: null,
  error: '',
};

const reducer = (state = initState, action: PostActionTypes): State => {
  if (action.type === SEARCH_POSTS_REQUEST) {
    return state;
  } else if (action.type === SEARCH_POSTS_SUCCESS) {
    const { postsList } = action.payload;
    return {
      ...state,
      postsList: postsList,
      error: '',
    };
  } else if (action.type === SEARCH_POSTS_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === GET_POSTS_REQUEST) {
    return state;
  } else if (action.type === GET_POSTS_SUCCESS) {
    const { postsList } = action.payload;
    return {
      ...state,
      postsList: postsList,
      error: '',
    };
  } else if (action.type === GET_POSTS_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === ADD_POST_REQUEST) {
    return state;
  } else if (action.type === ADD_POST_SUCCESS) {
    const { postsList } = state;
    const {
      id,
      content,
      images,
      userDisplayName,
      profilePicture,
      uid,
      likesCount,
      commentsCount,
    } = action.payload;
    return {
      ...state,
      error: '',
      postsList: [
        ...postsList,
        {
          id,
          content,
          images,
          userDisplayName,
          profilePicture,
          uid,
          likesCount,
          commentsCount,
        },
      ],
    };
  } else if (action.type === ADD_POST_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === LIKE_POST_REQUEST) {
    return state;
  } else if (action.type === LIKE_POST_SUCCESS) {
    const { id, uid } = action.payload;
    const { postsList } = state;
    const updatedPostsList = postsList?.map(post => {
      if (post.id === id) {
        return {
          ...post,
          likesCount: (post.likesCount || 0) + 1,
          likedByIDs: [...(post?.likedByIDs || []), uid],
        };
      }
      return post;
    });

    return {
      ...state,
      postsList: updatedPostsList,
      error: '',
    };
  } else if (action.type === LIKE_POST_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === DISLIKE_POST_REQUEST) {
    return state;
  } else if (action.type === DISLIKE_POST_SUCCESS) {
    const { id, uid } = action.payload;
    const { postsList } = state;
    const updatedPostsList = postsList?.map(post => {
      if (post?.id === id) {
        const newLikesCount = Math.max((post?.likesCount || 0) - 1, 0); // Decrement likesCount by 1, ensuring it doesn't go below 0
        return {
          ...post,
          likesCount: newLikesCount,
          likedByIDs: post?.likedByIDs?.filter(
            (userId: string) => userId !== uid,
          ),
        };
      }
      return post;
    });

    return {
      ...state,
      postsList: updatedPostsList,
      error: '',
    };
  } else if (action.type === DISLIKE_POST_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === UPDATE_POST_REQUEST) {
    return state;
  } else if (action.type === UPDATE_POST_SUCCESS) {
    const { postsList } = state;
    const { id, content, images, userDisplayName, profilePicture } =
      action.payload;
    const updatedPostsList = postsList?.map(post => {
      if (post?.id === id) {
        return {
          ...post,
          content,
          images,
          userDisplayName,
          profilePicture,
        };
      }
      return post;
    });
    return {
      ...state,
      error: '',
      postsList: updatedPostsList,
    };
  } else if (action.type === UPDATE_POST_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === GET_ONE_POST_REQUEST) {
    return state;
  } else if (action.type === GET_ONE_POST_SUCCESS) {
    const { post } = action.payload;
    return {
      ...state,
      singlePost: post,
      error: '',
    };
  } else if (action.type === GET_ONE_POST_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === UPDATE_COMMENTS_COUNT) {
    const { postId } = action.payload;
    const updatedPostsList = state.postsList?.map(post => {
      if (post?.id === postId) {
        return {
          ...post,
          commentsCount: (post?.commentsCount || 0) + 1,
        };
      }
      return post;
    });

    return {
      ...state,
      postsList: updatedPostsList,
      error: '',
    };
  }

  return state;
};

export default reducer;

import Address from '../../models/[new]address';
import {
  State,
  AddressActionTypes,
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAIL,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES_REQUEST,
  GET_ADDRESSES_FAIL,
  SET_SHIPPING_ADDRESS_ID,
  SET_BILLING_ADDRESS_ID,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAIL,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
} from '../types/addresses';

const initState: State = {
  addresses: [],
  error: '',
  defaultBillingAddress: '',
  defaultShippingAddress: '',
};

const reducer = (state = initState, action: AddressActionTypes): State => {
  if (action.type === ADD_ADDRESS_REQUEST) {
    return state;
  }
  if (action.type === ADD_ADDRESS_SUCCESS) {
    const { addresses } = state;

    const {
      id,
      addressLineOne,
      addressLineTwo,
      placeId,
      name,
      country,
      state: AddressState,
      suite,
      city,
      zipCode,
      lat,
      lng,
      isBilling,
      isShipping,
    } = action.payload;
    return {
      ...state,
      error: '',
      addresses: [
        ...addresses,
        {
          id,
          addressLineOne,
          addressLineTwo,
          placeId,
          name,
          country,
          state: AddressState,
          suite,
          city,
          zipCode,
          lat,
          lng,
        },
      ],
      defaultBillingAddress: isBilling ? id : state.defaultBillingAddress,
      defaultShippingAddress: isShipping ? id : state.defaultShippingAddress,
    };
  }
  if (action.type === ADD_ADDRESS_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === UPDATE_ADDRESS_REQUEST) {
    return state;
  } else if (action.type === UPDATE_ADDRESS_SUCCESS) {
    const {
      id,
      addressLineOne,
      addressLineTwo,
      placeId,
      country,
      name,
      state: AddressState,
      suite,
      city,
      zipCode,
      lat,
      lng,
      isBilling,
      isShipping,
    } = action.payload;

    const newAddresses = state.addresses.map((address: Address) => {
      return address.id === id
        ? {
            id,
            addressLineOne,
            addressLineTwo,
            placeId,
            country,
            name,
            state: AddressState,
            suite,
            city,
            zipCode,
            lat,
            lng,
          }
        : address;
    });

    if (isBilling || isShipping) {
      if (isShipping && isBilling)
        return {
          ...state,
          addresses: newAddresses,
          defaultBillingAddress: id,
          defaultShippingAddress: id,
        };
      else if (isBilling)
        return { ...state, addresses: newAddresses, defaultBillingAddress: id };
      else
        return {
          ...state,
          addresses: newAddresses,
          defaultShippingAddress: id,
        };
    }
    return { ...state, addresses: newAddresses };
  } else if (action.type === GET_ADDRESSES_REQUEST) {
    return state;
  } else if (action.type === GET_ADDRESSES_SUCCESS) {
    const { addresses } = action.payload;
    return {
      ...state,
      addresses,
    };
  } else if (action.type === GET_ADDRESSES_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === SET_SHIPPING_ADDRESS_ID) {
    const { id } = action.payload;
    return {
      ...state,
      defaultShippingAddress: id,
    };
  } else if (action.type === SET_BILLING_ADDRESS_ID) {
    const { id } = action.payload;
    return {
      ...state,
      defaultBillingAddress: id,
    };
  } else if (action.type === DELETE_ADDRESS_REQUEST) {
    return state;
  } else if (action.type === DELETE_ADDRESS_SUCCESS) {
    const { id } = action.payload;

    const { addresses } = state;
    const newAddresses = addresses.filter(address => address.id !== id);
    return {
      ...state,
      addresses: newAddresses,
      defaultBillingAddress:
        state.defaultBillingAddress === id ? '' : state.defaultBillingAddress,
      defaultShippingAddress:
        state.defaultShippingAddress === id ? '' : state.defaultShippingAddress,
    };
  } else if (action.type === DELETE_ADDRESS_FAIL) {
    const { error, id } = action.payload;
    return {
      ...state,
      error: error,
    };
  }
  return state;
};

export default reducer;

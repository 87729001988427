import { useEffect, useState } from 'react';
import { useTypedSelector } from '../redux/store';
import { getCartProductsSelector } from '../redux/actions/products';

const useCartTotals = () => {
  const myCart = useTypedSelector(state => state.cart.cart);
  const shippingCompany = useTypedSelector(state => state.cart.cartShippment);
  const myCartItems = useTypedSelector(getCartProductsSelector);
  const [shippingCost, setShippingCost] = useState<number>(0);

  const [itemsCost, setItemsCost] = useState<number>(0);
  const [totalTaxes, setTotalTaxes] = useState<number>(0);
  const [finalTotal, setFinalTotal] = useState<number>(0);
  const [totalbeforeTax, setTotalbeforeTax] = useState<number>(0);

  const calculateShipping = () => {
    let shippingTotal = 0;
    let idsArr: (string | undefined)[] = [];

    shippingCompany.forEach(item => {
      if (item.companyIndex >= 0 && !item.isLocalPickup) {
        const itemProduct = myCartItems.find(
          product => product?.id ===  item.productId,
        );
        const shippingPrice =
          itemProduct?.shipmentCompanies[item.companyIndex]?.price;
        if (shippingPrice && typeof shippingPrice ===  'number') {
          if (idsArr?.includes(item?.productId) ===  false) {
            shippingTotal += shippingPrice;
          }
          if (idsArr?.includes(item?.productId) ===  false) {
            idsArr.push(item?.productId);
          }
        }
      }
    });
    return shippingTotal;
  };

  const calculateItemsTotal = () => {
    let itemsTotal = 0;
    myCart.forEach(item => {
      if (item.quantity > 0) {
        const itemProduct = myCartItems.find(product => product?.id ===  item.id);
        const itemPrice = itemProduct?.price;
        if (itemPrice && typeof itemPrice ===  'number') {
          itemsTotal += itemPrice * item.quantity;
        }
      }
    });
    return itemsTotal;
  };

  const calculateTotalTaxes = () => {
    let itemsTotalTaxes = 0;
    myCart.forEach(item => {
      if (item.quantity > 0) {
        const itemProduct = myCartItems.find(product => product?.id ===  item.id);
        const itemPrice = itemProduct?.price;
        if (
          itemProduct?.taxRate &&
          itemProduct?.taxRate > 0 &&
          itemPrice &&
          typeof itemPrice ===  'number'
        ) {
          itemsTotalTaxes +=
            (itemProduct.taxRate / 100) * (itemPrice * itemProduct.quantity);
        }
      }
    });
    return itemsTotalTaxes;
  };

  useEffect(() => {
    const shipping = calculateShipping();
    const items = calculateItemsTotal();
    const taxes = calculateTotalTaxes();

    setTotalTaxes(taxes);
    setItemsCost(items);
    setShippingCost(shipping);
    setTotalbeforeTax(shipping + items);
    setFinalTotal(shipping + items + taxes);
  }, [
    myCartItems,
    myCart,
    myCart.length,
    shippingCompany,
    shippingCompany.length,
  ]);

  return {
    shippingCost: shippingCost,
    itemsPrice: itemsCost,
    totalTaxes: totalTaxes,
    totalOrders: finalTotal,
    totalbeforeTax: totalbeforeTax,
  };
};

export default useCartTotals;

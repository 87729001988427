// actions
// keep in mind to update the URL search Params and depend on Them for the search, update ur data from algolia regularay

import { Product } from '../../models/[new]product';
import { PriceSortOpts } from '../../@types/searchPageTypes';
import { User } from '../../models/[new]user';

// SET_PRICE_SORT "low to height", "height to low", "none"
// SET_PRICE_RANGE_FILTER [1,1000] || []
// SET_PAGE_ZIZE 12 24 48
// SET_AGE_SORT "string"
// ADD_CATEGORY_FILTER
// REMOVE_CATEGORY_FILTER
// CLEAR_SEARCH_FILTERS

export const GET_BEST_PRODUCTS_REQUEST = 'GET_BEST_PRODUCTS_REQUEST';
export const GET_BEST_PRODUCTS_SUCCESS = 'GET_BEST_PRODUCTS_SUCCESS';
export const GET_BEST_PRODUCTS_FAIL = 'GET_BEST_PRODUCTS_FAIL';

export const SET_PRICE_SORT = 'SET_PRICE_SORT';
export const SET_AGE_FILTER = 'SET_AGE_FILTER';
export const SET_PRICE_RANGE_FILTER = 'SET_PRICE_RANGE_FILTER';
export const SET_DISTANCE_RANGE_FILTER = ' SET_DISTANCE_RANGE_FILTER';

export const SET_PAGE_SIZE = 'SET_PAGE_ZIZE';
export const SET_PAGE_NO = 'SET_PAGE_NO';
export const ADD_CATEGORY_FILTER = 'ADD_CATEGORY_FILTER';
export const REMOVE_CATEGORY_FILTER = 'REMOVE_CATEGORY_FILTER';
export const SET_CATEGORY_FILTERS = 'SET_CATEGORY_FILTERS';
export const SET_ZIP_CODE_FILTER = 'SET_ZIP_CODE_FILTER';
export const SET_RESULTS_TYPE = 'SET_RESULTS_TYPE';
export const SET_TEXT_FILTER = 'SET_TEXT_FILTER';

export const CLEAR_SEARCH_FILTERS = 'CLEAR_SEARCH_FILTERS';
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const NO_RESULT = 'NO_RESULT';
export const SET_RESULT_SHOPS = 'NO_RESULT_SHOPS';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAIL = 'SEARCH_FAIL';
export const SET_NO_RESULT = 'SET_NO_RESULT';
export const SET_NO_RESULT_SHOPS = 'SET_NO_RESULT_SHOPS';
export const SEARCH_SHOPS_REQUEST = 'SEARCH_SHOPS_REQUEST';
export const SEARCH_SHOPS_SUCCESS = 'SEARCH_SHOPS_SUCCESS';
export const SET_RADIUS_TYPE = 'SET_RADIUS_TYPE';
export const CLEAR_PRODUCTS_LIST = 'CLEAR_PRODUCTS_LIST';
export const CLEAR_SHOPS_LIST = 'CLEAR_SHOPS_LIST';

export const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM';
export const SET_MAP_CENTER = 'SET_MAP_CENTER';

// algolia call in each  action dispatched A thunk
export enum RADIUS_TYPES {
  CUSTOM_RADIUS = 'CUSTOM_RADIUS',
  CITY_RADIUS = 'CITY_RADIUS',
  DONT_LIMIT_RADIUS = 'DONT_LIMIT_RADIUS',
}

interface GetBestProductsRequest {
  type: typeof GET_BEST_PRODUCTS_REQUEST;
  payload: {};
}

interface GetBestProductsSuccess {
  type: typeof GET_BEST_PRODUCTS_SUCCESS;
  payload: {
    products: Product[];
  };
}

interface GetBestProductsFail {
  type: typeof GET_BEST_PRODUCTS_FAIL;
  payload: {
    error: string;
  };
}
interface SetNoResultShops {
  type: typeof SET_NO_RESULT_SHOPS;
  payload: {
    noResultShops: boolean;
  };
}

interface SetTextFilter {
  type: typeof SET_TEXT_FILTER;
  payload: {
    text: string;
  };
}

interface SetPriceSort {
  type: typeof SET_PRICE_SORT;
  payload: {
    sort: PriceSortOpts;
  };
}

interface SetAgeFilter {
  type: typeof SET_AGE_FILTER;
  payload: {
    age: number;
  };
}

interface SetZipCodeFilter {
  type: typeof SET_ZIP_CODE_FILTER;
  payload: {
    lat: number;
    lng: number;
    loc: any;
  };
}

interface SetPriceRangeFilter {
  type: typeof SET_PRICE_RANGE_FILTER;
  payload: {
    from: number;
    to: number;
  };
}

interface SetDistanceRangeFilter {
  type: typeof SET_DISTANCE_RANGE_FILTER;
  payload: {
    km: number;
  };
}

interface SetPageSize {
  type: typeof SET_PAGE_SIZE;
  payload: {
    size: number;
  };
}

interface SetPageNo {
  type: typeof SET_PAGE_NO;
  payload: {
    num: number;
  };
}

interface AddCategoryFilter {
  type: typeof ADD_CATEGORY_FILTER;
  payload: {
    category: string;
  };
}

interface AddResultTypeFilter {
  type: typeof SET_RESULTS_TYPE;
  payload: {
    resultsType: 'listings' | 'shops';
  };
}

interface RemoveCategoryFilter {
  type: typeof REMOVE_CATEGORY_FILTER;
  payload: {
    category: string;
  };
}

interface SetCategoryFilters {
  type: typeof SET_CATEGORY_FILTERS;
  payload: {
    categories: string[];
  };
}

interface ClearSearchFilters {
  type: typeof CLEAR_SEARCH_FILTERS;
  payload: {};
}

interface ClearProductsList {
  type: typeof CLEAR_PRODUCTS_LIST;
  payload: {};
}

interface ClearShopsList {
  type: typeof CLEAR_SHOPS_LIST;
  payload: {};
}

interface SearchRequest {
  type: typeof SEARCH_REQUEST;
  payload: {};
}
interface NoResultRequest {
  type: typeof NO_RESULT;
  payload: {};
}

interface SearchSuccess {
  type: typeof SEARCH_SUCCESS;
  payload: {
    list: Product[];
  };
}

interface SearchShopsRequest {
  type: typeof SEARCH_SHOPS_REQUEST;
  payload: {};
}

interface SearchShopsSuccess {
  type: typeof SEARCH_SHOPS_SUCCESS;
  payload: {
    shops: User[];
  };
}

interface SearchFail {
  type: typeof SEARCH_FAIL;
  payload: {
    error: string;
  };
}
interface SetNoResult {
  type: typeof SET_NO_RESULT;
  payload: {
    noResult: boolean;
  };
}

interface SetRadiusType {
  type: typeof SET_RADIUS_TYPE;
  payload: {
    type: RADIUS_TYPES;
  };
}

interface SetCurrentCategory {
  type: typeof SET_CURRENT_CATEGORY;
  payload: {
    currentCategory: string;
  };
}

interface SetMapZoom {
  type: typeof SET_MAP_ZOOM;
  payload: {
    mapZoom: number;
  };
}

interface SetMapCenter {
  type: typeof SET_MAP_CENTER;
  payload: {
    mapCenter: google.maps.LatLngLiteral;
  };
}

export type ActionTypes =
  | GetBestProductsRequest
  | GetBestProductsSuccess
  | GetBestProductsFail
  | SetTextFilter
  | SetPriceSort
  | SetAgeFilter
  | SetPriceRangeFilter
  | SetDistanceRangeFilter
  | SetPageSize
  | SetZipCodeFilter
  | SetPageNo
  | AddCategoryFilter
  | RemoveCategoryFilter
  | SetCategoryFilters
  | ClearSearchFilters
  | SearchRequest
  | NoResultRequest
  | SearchSuccess
  | SearchFail
  | SetRadiusType
  | AddResultTypeFilter
  | SearchShopsSuccess
  | SearchShopsRequest
  | ClearProductsList
  | ClearShopsList
  | SetNoResult
  | SetNoResultShops
  | SetCurrentCategory
  | SetMapZoom
  | SetMapCenter;

// interface AddCategoryFilter

export interface SearchPageState {
  list: Product[];
  shops: User[];
  error: string;
  noResult: boolean;
  noResultShops: boolean;
  currentCategory: string;
  mapZoom: number;
  mapCenter: google.maps.LatLngLiteral;
  searchFilters: {
    text: string;
    categories: string[];
    priceRange: {
      from: number;
      to: number;
    };
    priceSort: PriceSortOpts;
    itemsPerPage: number;
    dateOfMadeSort: string;
    page: number;
    age: number;
    zipCode: number | null;
    distance: {
      aroundLatLngViaIP: boolean;
      KMRadius: number;
    };
    location: {
      lat: number;
      lng: number;
      loc: any;
    };
    radiusType: RADIUS_TYPES;
    resultsType: 'listings' | 'shops';
  };
}

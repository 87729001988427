import CreditCard from '../../models/credit-card';
import PayPal from '../../models/[new]paypal';

export const ADD_CARD_REQUEST = 'ADD_CARD_REQUEST';
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';
export const ADD_CARD_FAIL = 'ADD_CARD_FAIL';

export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAIL = 'DELETE_CARD_FAIL';

export const GET_CARDS_REQUEST = 'GET_CARDS_REQUEST';
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS';
export const GET_CARDS_FAIL = 'GET_CARDS_FAIL';

export const ADD_PAYPAL_REQUEST = 'ADD_PAYPAL_REQUEST';
export const ADD_PAYPAL_SUCCESS = 'ADD_PAYPAL_SUCCESS';
export const ADD_PAYPAL_FAIL = 'ADD_PAYPAL_FAIL';

export const DELETE_PAYPAL_REQUEST = 'DELETE_PAYPAL_REQUEST';
export const DELETE_PAYPAL_SUCCESS = 'DELETE_PAYPAL_SUCCESS';
export const DELETE_PAYPAL_FAIL = 'DELETE_PAYPAL_FAIL';

export const GET_PAYPAL_REQUEST = 'GET_PAYPAL_REQUEST';
export const GET_PAYPAL_SUCCESS = 'GET_PAYPAL_SUCCESS';
export const GET_PAYPAL_FAIL = 'GET_PAYPAL_FAIL';

interface AddPaypalRequest {
  type: typeof ADD_PAYPAL_REQUEST;
  payload: {};
}

interface AddPaypalSuccess {
  type: typeof ADD_PAYPAL_SUCCESS;
  payload: {
    account: PayPal;
  };
}
interface AddPaypalFail {
  type: typeof ADD_PAYPAL_FAIL;
  payload: {
    error: string;
  };
}

interface GetPaypalRequest {
  type: typeof GET_PAYPAL_REQUEST;
  payload: {};
}

interface GetPaypalSuccess {
  type: typeof GET_PAYPAL_SUCCESS;
  payload: {
    account: PayPal;
  };
}

interface GetPaypalFail {
  type: typeof GET_PAYPAL_FAIL;
  payload: {
    error: string;
  };
}

interface DeletePaypalRequest {
  type: typeof DELETE_PAYPAL_REQUEST;
  payload: {};
}

interface DeletePaypalSuccess {
  type: typeof DELETE_PAYPAL_SUCCESS;
  payload: {};
}
interface DeletePaypalFail {
  type: typeof DELETE_PAYPAL_FAIL;
  payload: {
    error: string;
  };
}

interface AddCardRequest {
  type: typeof ADD_CARD_REQUEST;
  payload: {};
}

interface AddCardSuccess {
  type: typeof ADD_CARD_SUCCESS;
  payload: {
    card: CreditCard;
  };
}

interface AddCardFail {
  type: typeof ADD_CARD_FAIL;
  payload: {
    error: string;
  };
}

interface GetCardsRequest {
  type: typeof GET_CARDS_REQUEST;
  payload: {};
}

interface GetCardsSuccess {
  type: typeof GET_CARDS_SUCCESS;
  payload: {
    cards: CreditCard[];
  };
}

interface GetCardsFail {
  type: typeof GET_CARDS_FAIL;
  payload: {
    error: string;
  };
}

interface DeleteCardRequest {
  type: typeof DELETE_CARD_REQUEST;
  payload: {};
}

interface DeleteCardSuccess {
  type: typeof DELETE_CARD_SUCCESS;
  payload: {
    id: string;
  };
}

interface DeleteCardFail {
  type: typeof DELETE_CARD_FAIL;
  payload: {
    id: string;
    error: string;
  };
}

export type ActionTypes =
  | AddCardRequest
  | AddCardSuccess
  | AddCardFail
  | DeleteCardRequest
  | DeleteCardSuccess
  | DeleteCardFail
  | GetCardsRequest
  | GetCardsSuccess
  | GetCardsFail
  | AddPaypalRequest
  | AddPaypalSuccess
  | AddPaypalFail
  | GetPaypalRequest
  | GetPaypalSuccess
  | GetPaypalFail
  | DeletePaypalRequest
  | DeletePaypalSuccess
  | DeletePaypalFail;

export type StateType = {
  cards: {
    list: CreditCard[];
    error: string;
  };
  paypal: {
    error: string;
    account: PayPal | null;
  };
};

/*
    payment {
        cards:{Card[],error:""},
        paypal:{
            error:"",
            ...PaypalData
        },
        orders:{

        },
        histpry:{}
    }
{sources Reducer}
ADD_PAYPAL_REQUEST
ADD_PAYPAL_SUCCESS
ADD_PAYPAL_FAIL


REMOVE_PAYPAL_REQUEST
REMOVE_PAYPAL_SUCCESS
REMOVE_PAYPAL_FAIL

GET_PAYPAL_REQUEST
GET_PAYPAL_SUCCESS
GET_PAYPAL_FAIL

*/

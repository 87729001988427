import firebase from 'firebase/app';

interface ReplyType {
  id?: string;
  content: string;
  createdAt?: firebase.firestore.Timestamp | Date;
  uid: string;
  userDisplayName: string;
  profilePicture: string;
  postID?: string;
  commentId?: string;
}

export default class Reply implements ReplyType {
  id?: string;
  content: string;
  createdAt?: firebase.firestore.Timestamp | Date | null | any;
  uid: string;
  userDisplayName: string;
  profilePicture: string;
  postID?: string;
  commentId?: string;

  constructor(data?: ReplyType) {
    this.id = data?.id ?? '';
    this.content = data?.content ?? '';
    this.createdAt = data?.createdAt ?? null;
    this.uid = data?.uid ?? '';
    this.userDisplayName = data?.userDisplayName ?? '';
    this.profilePicture = data?.profilePicture ?? '';
    this.postID = data?.postID ?? '';
    this.commentId = data?.commentId ?? '';
  }
}

export const replyConverter: firebase.firestore.FirestoreDataConverter<Reply> =
  {
    toFirestore: (reply: Partial<Reply>): firebase.firestore.DocumentData => {
      return {
        ...reply,
      };
    },
    fromFirestore: (
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ) => {
      const data = snapshot.data(options) as Reply;
      return new Reply({
        id: snapshot.id,
        ...(data as Reply),
      });
    },
  };

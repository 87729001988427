import {
  GET_USER_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  PUSH_TO_RECENTLY_VIEWED,
  SET_PROFILE_PICTURE_FAIL,
  SET_PROFILE_PICTURE_REQUEST,
  SET_PROFILE_PICTURE_SUCCESS,
  SET_USER_FAIL,
  SET_USER_REQUEST,
  SET_USER_SUCCESS,
  UserActionTypes,
  SET_SHOP_LOGO_REQUEST,
  SET_SHOP_LOGO_SUCCESS,
  SET_SHOP_LOGO_FAIL,
} from '../types/user';
import {
  usersC,
  privateUsersC,
  storage,
  userNamesC,
} from '../../utils/firebase-utils';
import { AppThunk } from '../store';
import {
  OpenDays,
  SellerData,
  Shop,
  userConverter,
  UserType,
} from '../../models/[new]user';
import {
  privateUserConverter,
  PrivateUserType,
} from '../../models/private-user';
import { logoutThunk } from './auth';
import {
  Something_Went_Wrong,
  USERNAME_TAKEN_ERROR,
} from '../../utils/constants';
import { filterObject } from '../../utils/filterObject';
import { FormikHelpers } from 'formik';
import { setBillingAddressId, setShippingAddressId } from './addresses';
import { start } from 'repl';
import { ConnectAccount } from '../../models/connect-account';
import { endPoint, storageFB } from '../../constants/constants';

export default () => {};

const getUserRequestAction = (): UserActionTypes => {
  return {
    type: GET_USER_REQUEST,
    payload: {},
  };
};

const getUserSuccessAction = ({
  uid,
  email,
  userName,
  firstName,
  middleName,
  websiteLink,
  lastName,
  gender,
  state,
  city,
  phoneNumber,
  profilePicture,
  ssn,
  registeredSince,
  completionRate,
  defaultBillingAddress,
  defaultShippingAddress,
  dob,
  taxRate,
  followersCount,
  bankAccount,
  itemsSelling,
  itemsSold,
  paymentType,
  itemsDraft,
  bag,
  wishList,
  recentlyViewed,
  sellerReviewIndex,
  userType,
  shop,
  firstOne,
  birthDate,
  isDisabled,
  sellerData,
}: UserType & PrivateUserType): UserActionTypes => {
  return {
    type: GET_USER_SUCCESS,
    payload: {
      uid,
      email,
      userName,
      firstName,
      middleName,
      websiteLink,
      lastName,
      gender,
      state,
      city,
      phoneNumber,
      profilePicture,
      ssn,
      registeredSince,
      bag,
      bankAccount,
      completionRate,
      defaultBillingAddress,
      defaultShippingAddress,
      dob,
      taxRate,
      followersCount,
      itemsDraft,
      itemsSelling,
      itemsSold,
      paymentType,
      wishList,
      recentlyViewed,
      sellerReviewIndex,
      userType,
      shop,
      firstOne,
      birthDate,
      isDisabled,
      sellerData,
    },
  };
};

const pushToRecentlyViewed = ({ id }: { id: string }): UserActionTypes => {
  return {
    type: PUSH_TO_RECENTLY_VIEWED,
    payload: {
      id,
    },
  };
};

const getUserFailAction = (error?: string): UserActionTypes => {
  return {
    type: GET_USER_FAIL,
    payload: {
      error,
    },
  };
};

export const getUserThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(getUserRequestAction());

    const {
      auth: { uid },
    } = getState();

    try {
      const [userRef, privateUserRef, bankAccountRef] = await Promise.all([
        usersC.doc(uid).withConverter(userConverter).get(),
        privateUsersC.doc(uid).withConverter(privateUserConverter).get(),
        privateUsersC.doc(uid).collection('connect_account').get(),
      ]);
      if (!userRef.exists || !privateUserRef.exists) {
        dispatch(getUserFailAction());
        dispatch(logoutThunk());
      }

      if (!uid) {
        dispatch(getUserFailAction());
        dispatch(logoutThunk());
      }

      const userData = {
        ...userRef.data(),
      } as UserType;

      const privateUserData: PrivateUserType = {
        ...privateUserRef.data(),
      } as PrivateUserType;

      let bankAccountData: null | ConnectAccount = null;

      if (!bankAccountRef.empty && bankAccountRef?.docs[0]?.exists) {
        let data = bankAccountRef.docs[0].data();
        data.id = bankAccountRef.docs[0].id;
        bankAccountData = new ConnectAccount(data);
      }

      dispatch(
        getUserSuccessAction({
          ...userData,
          ...privateUserData,
          bankAccount: bankAccountData,
        }),
      );
      const { defaultShippingAddress, defaultBillingAddress } = privateUserData;
      dispatch(setShippingAddressId({ id: defaultShippingAddress }));
      dispatch(setBillingAddressId({ id: defaultBillingAddress }));
    } catch (e) {
      dispatch(getUserFailAction(e?.message));
      dispatch(logoutThunk());
    }
  };
};

export const setUserRequest = (): UserActionTypes => {
  return {
    type: SET_USER_REQUEST,
    payload: {},
  };
};

export const setUserFail = ({ error }: { error: string }): UserActionTypes => {
  return {
    type: SET_USER_FAIL,
    payload: {
      error,
    },
  };
};

export const setUserSuccess = (
  values: Partial<
    Omit<
      UserType & PrivateUserType,
      'wishList' | 'profilePicture' | 'uid' | 'defaultBillingAddress' | 'bag'
    >
  >,
): UserActionTypes => {
  const bankAccount = values?.bankAccount ?? null;
  return {
    type: SET_USER_SUCCESS,
    payload: {
      ...values,
      bankAccount,
    },
  };
};

export const setUserThunk = (
  values: Partial<
    Omit<
      UserType & PrivateUserType,
      'wishList' | 'profilePicture' | 'uid' | 'defaultBillingAddress' | 'bag'
    >
  >,
  formikHelpers?: FormikHelpers<any>,
): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
      user: { shop: userShop, userType, sellerData: userSeller },
    } = getState();
    dispatch(setUserRequest());

    try {
      const { email, userName, ...rest } = values;
      // trim the leading zeroes of the tax rate
      rest.taxRate = Number(values?.taxRate);
      // in case the taxRate did not change,
      if (values?.taxRate === undefined) {
        rest.taxRate = values?.taxRate;
      }
      // public info
      const {
        firstName,
        middleName,
        lastName,
        state,
        city,
        websiteLink,
        paymentType,
        firstOne,
        defaultShippingAddress,
      } = rest;

      // @ts-ignore
      const {
        // @ts-ignore
        location,
        // @ts-ignore
        openDays,
        // @ts-ignore
        shopEmail,
        // @ts-ignore
        shopPhoneNumber,
        // @ts-ignore
        description,
        // @ts-ignore
        isAppointmentOnly,
        // @ts-ignore
        shopName,
        // @ts-ignore
        shopLogo,
        userType: valuesUserType,
        // @ts-ignore
        shopWebsiteLink,
      } = rest;

      const {
        // @ts-ignore
        sellerEmail,
        // @ts-ignore
        location: sellerLocation,
        // @ts-ignore
        sellerPhoneNumber,
        // @ts-ignore
        sellerName,
        // @ts-ignore
        showOnMap,
        // @ts-ignore
        sellerWebsiteLink,
      } = rest;

      let shop;
      let sellerData;
      let _geoloc;

      if (valuesUserType === 'shop') {
        let newDownloadURL: string = '';
        if (shopLogo) {
          const uploadRef = storage
            .ref()
            .child(`/profileImages/${uid}/shopLogo`);
          await uploadRef.put(shopLogo as Blob);

          await uploadRef.getDownloadURL().then(downloadURL => {
            downloadURL = downloadURL.replace(storageFB, endPoint);
            newDownloadURL = downloadURL;
          });
        }

        if (isAppointmentOnly === false) {
          if (openDays?.length === 0) {
            dispatch(
              setUserFail({ error: 'You must provide the hours of operation' }),
            );
            return;
          }
        }

        shop = {
          location: location ?? userShop?.location,
          openDays:
            (openDays?.map((o: OpenDays) =>
              Object.assign({}, { ...o }),
            ) as any) ??
            (userShop?.openDays?.map((o: OpenDays) =>
              Object.assign({}, { ...o }),
            ) as any),
          email: shopEmail ?? userShop?.email,
          phoneNumber: shopPhoneNumber ?? userShop?.phoneNumber,
          description: description ?? userShop?.description,
          isAppointmentOnly: isAppointmentOnly ?? userShop?.isAppointmentOnly,
          shopName: shopName ?? userShop?.shopName,
          shopLogo: shopLogo ? newDownloadURL : userShop?.shopLogo,
          shopWebsiteLink: shopWebsiteLink ?? userShop?.shopWebsiteLink,
        };
        _geoloc = Object.assign(
          {},
          {
            lat: location ? location?.lat : userShop?.location?.lat ?? null,
            lng: location ? location?.lng : userShop?.location?.lng ?? null,
          },
        );
      } else if (valuesUserType === 'seller') {
        sellerData = {
          email: sellerEmail ?? userSeller?.email,
          location: sellerLocation ?? userSeller?.location,
          phoneNumber: sellerPhoneNumber ?? userSeller?.phoneNumber,
          sellerName: sellerName ?? userSeller?.sellerName,
          showOnMap: showOnMap ?? userSeller?.showOnMap,
          websiteLink: sellerWebsiteLink ?? userSeller?.websiteLink,
        };

        _geoloc = Object.assign(
          {},
          {
            lat: sellerLocation
              ? sellerLocation?.lat
              : userSeller?.location?.lat ?? null,
            lng: sellerLocation
              ? sellerLocation?.lng
              : userSeller?.location?.lng ?? null,
          },
        );
      }

      await usersC
        .withConverter(userConverter)
        .doc(uid)
        .set(
          {
            ...filterObject({
              firstName,
              middleName,
              websiteLink,
              lastName,
              state,
              city,
              userType: valuesUserType,
              paymentType,
              firstOne,
              defaultShippingAddress,
            }),

            _geoloc: _geoloc?.lat ? _geoloc : null,
          },
          { merge: true },
        );

      if (valuesUserType === 'shop') {
        await usersC
          .withConverter(userConverter)
          .doc(uid)
          .set(
            {
              shop: shop as Shop,
            },
            { merge: true },
          );
      } else if (valuesUserType === 'seller') {
        await usersC
          .withConverter(userConverter)
          .doc(uid)
          .set(
            {
              sellerData: sellerData as SellerData,
            },
            { merge: true },
          );
      }
      // private info update
      const { ssn, dob, phoneNumber, taxRate, gender, birthDate } = rest;
      await privateUsersC.withConverter(privateUserConverter).doc(uid).set(
        filterObject({
          ssn,
          dob,
          phoneNumber,
          taxRate,
          gender,
          birthDate,
        }),
        {
          merge: true,
        },
      );

      const bankAccountRef = await privateUsersC
        .doc(uid)
        .collection('connect_account')
        .get();
      let bankAccount: ConnectAccount | null = null;
      if (bankAccountRef?.docs[0]?.exists) {
        let data = bankAccountRef.docs[0]?.data();
        data.id = bankAccountRef.docs[0]?.id;
        bankAccount = new ConnectAccount(data);
      }

      // update user name
      if (userName) {
        const { exists } = await userNamesC.doc(userName).get();

        if (exists) {
          formikHelpers?.setFieldError('userName', USERNAME_TAKEN_ERROR);
          return dispatch(setUserFail({ error: USERNAME_TAKEN_ERROR }));
        } else {
          //get the old username to delete it from firebase
          const userData = await usersC
            .doc(uid)
            .withConverter(userConverter)
            .get();
          const oldUsername = userData.data()?.userName;

          await Promise.all([
            usersC
              .withConverter(userConverter)
              .doc(uid)
              .set({ userName }, { merge: true }),
            userNamesC.doc(userName).set({ userName }, { merge: true }),
            userNamesC.doc(oldUsername).delete(),
          ]);
        }
      }
      // TODO update email
      dispatch(
        setUserSuccess(
          filterObject({
            firstName,
            middleName,
            lastName,
            websiteLink,
            userName,
            city,
            state,
            email,
            gender,
            dob,
            ssn,
            bankAccount,
            taxRate,
            phoneNumber,
            shop: shop,
            userType: valuesUserType,
            birthDate,
            sellerData: sellerData,
          }),
        ),
      );
    } catch (e) {
      dispatch(setUserFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};

const setProfilePictureRequest = (): UserActionTypes => {
  return {
    type: SET_PROFILE_PICTURE_REQUEST,
    payload: {},
  };
};
export const setProfilePictureSuccess = ({
  url,
}: {
  url: string;
}): UserActionTypes => {
  return {
    type: SET_PROFILE_PICTURE_SUCCESS,
    payload: {
      url,
    },
  };
};

const setProfilePictureFail = ({
  error,
}: {
  error?: string;
}): UserActionTypes => {
  return {
    type: SET_PROFILE_PICTURE_FAIL,
    payload: { error },
  };
};

// TODO:Remove the old one
export const setProfilePictureThunk = ({
  blob,
}: {
  blob: Blob | string;
}): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setProfilePictureRequest());
    const {
      user: { uid },
    } = getState();

    try {
      let url = '';
      let newDownloadURL: string = '';

      if (blob === '') {
      } else {
        const uploadRef = storage
          .ref()
          .child(`/profileImages/${uid}/profilePicture`);
        await uploadRef.put(blob as Blob);

        await uploadRef.getDownloadURL().then(downloadURL => {
          downloadURL = downloadURL.replace(storageFB, endPoint);
          newDownloadURL = downloadURL;
        });
      }

      await usersC.withConverter(userConverter).doc(uid).set(
        {
          profilePicture: newDownloadURL,
        },
        { merge: true },
      );
      dispatch(setProfilePictureSuccess({ url: newDownloadURL }));
    } catch (e) {
      dispatch(setProfilePictureFail({ error: Something_Went_Wrong }));
    }
  };
};

export const removeProfilePictureThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setProfilePictureRequest());
    const {
      user: { uid },
    } = getState();

    try {
      await usersC.withConverter(userConverter).doc(uid).set(
        {
          profilePicture: '',
        },
        { merge: true },
      );
      dispatch(setProfilePictureSuccess({ url: '' }));
    } catch (e) {
      dispatch(setProfilePictureFail({ error: Something_Went_Wrong }));
    }
  };
};

const setShopLogoRequest = (): UserActionTypes => {
  return {
    type: SET_SHOP_LOGO_REQUEST,
    payload: {},
  };
};
export const setShopLogoSuccess = ({
  url,
}: {
  url: string;
}): UserActionTypes => {
  return {
    type: SET_SHOP_LOGO_SUCCESS,
    payload: {
      url,
    },
  };
};

const setShopLogoFail = ({ error }: { error?: string }): UserActionTypes => {
  return {
    type: SET_SHOP_LOGO_FAIL,
    payload: { error },
  };
};

// TODO:Remove the old one
export const setShopLogoThunk = ({
  blob,
}: {
  blob: Blob | string;
}): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setShopLogoRequest());
    const {
      user: { uid },
    } = getState();

    try {
      let url = '';
      let newDownloadURL: string = '';

      if (blob === '') {
      } else {
        const uploadRef = storage.ref().child(`/profileImages/${uid}/shopLogo`);
        await uploadRef.put(blob as Blob);

        await uploadRef.getDownloadURL().then(downloadURL => {
          downloadURL = downloadURL.replace(storageFB, endPoint);
          newDownloadURL = downloadURL;
        });
      }

      await usersC
        .withConverter(userConverter)
        .doc(uid)
        .set(
          {
            shop: {
              shopLogo: newDownloadURL,
            } as Shop,
          },
          { merge: true },
        );
      dispatch(setShopLogoSuccess({ url: newDownloadURL }));
    } catch (e) {
      dispatch(setShopLogoFail({ error: Something_Went_Wrong }));
    }
  };
};

export const removeShopLogoThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setShopLogoRequest());
    const {
      user: { uid },
    } = getState();

    try {
      await usersC
        .withConverter(userConverter)
        .doc(uid)
        .set(
          {
            shop: {
              shopLogo: '',
            } as Shop,
          },
          { merge: true },
        );
      dispatch(setShopLogoSuccess({ url: '' }));
    } catch (e) {
      dispatch(setShopLogoFail({ error: Something_Went_Wrong }));
    }
  };
};

export const setUsernameThunk = ({
  username,
}: {
  username: string;
}): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setUserRequest());
    const {
      auth: { uid },
    } = getState();
    
    try {
      if (username !== ' ') {
        const userNameTaken = (await userNamesC.doc(username).get()).exists;
        if (userNameTaken) {
          return dispatch(
            setUserFail({ error: 'Username is taken, please try another' }),
          );
        }
      }

      const finalUsername = username === '' ? ' ' : username;

      await Promise.all([
        userNamesC.doc(username).set({
          username: finalUsername,
        }),
        usersC.withConverter(userConverter).doc(uid).set(
          {
            userName: finalUsername,
          },
          { merge: true },
        ),
      ]);
      dispatch(setUserSuccess({ userName: finalUsername }));
    } catch (e) {
      dispatch(setUserFail({ error: e.message || 'Something went wrong' }));
    }
  };
};

// export const setUsernameThunk = ({
//   username,
// }: {
//   username: string;
// }): AppThunk => {
//   return async (dispatch, getState) => {
//     dispatch(setUserRequest());
//     const {
//       auth: { uid },
//     } = getState();
//     try {
//       if (username === '')
//         return dispatch(setUserFail({ error: 'Please enter a username' }));
//       const userNameTaken = (await userNamesC.doc(username).get()).exists;
//       if (userNameTaken)
//         return dispatch(
//           setUserFail({ error: 'Username is taken, please try another' }),
//         );
//       await Promise.all([
//         userNamesC.doc(username).set({
//           username,
//         }),
//         usersC.withConverter(userConverter).doc(uid).set(
//           {
//             userName: username,
//           },
//           { merge: true },
//         ),
//       ]);
//       dispatch(setUserSuccess({ userName: username }));
//     } catch (e) {
//       dispatch(setUserFail({ error: e.message || Something_Went_Wrong }));
//     }
//   };
// };

export const pushToRecentlyViewedThunk = ({ id }: { id: string }): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();

    const { recentlyViewed: mostUpToDateRecentlyViewed } = (await (
      await privateUsersC.doc(uid).withConverter(privateUserConverter).get()
    ).data()) as PrivateUserType;

    let newRecentlyViewed = [...mostUpToDateRecentlyViewed];
    newRecentlyViewed.pop();
    newRecentlyViewed.unshift(id);

    try {
      await privateUsersC.doc(uid).withConverter(privateUserConverter).set(
        {
          recentlyViewed: newRecentlyViewed,
        },
        { merge: true },
      );
      dispatch(pushToRecentlyViewed({ id }));
    } catch (e) {
      console.log(e);
    }
  };
};

import Post from '../../models/[new]post';

export const SEARCH_POSTS_REQUEST = 'SEARCH_POSTS_REQUEST';
export const SEARCH_POSTS_SUCCESS = 'SEARCH_POSTS_SUCCESS';
export const SEARCH_POSTS_FAIL = 'SEARCH_POSTS_FAIL';

export const GET_POSTS_REQUEST = 'GET_POSTS_REQUEST';
export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS';
export const GET_POSTS_FAIL = 'GET_POSTS_FAIL';

export const ADD_POST_REQUEST = 'ADD_POST_REQUEST';
export const ADD_POST_SUCCESS = 'ADD_POST_SUCCESS';
export const ADD_POST_FAIL = 'ADD_POST_FAIL';

export const UPDATE_POST_REQUEST = 'UPDATE_POST_REQUEST';
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS';
export const UPDATE_POST_FAIL = 'UPDATE_POST_FAIL';

export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAIL = 'DELETE_POST_FAIL';

export const LIKE_POST_REQUEST = 'LIKE_POST_REQUEST';
export const LIKE_POST_SUCCESS = 'LIKE_POST_SUCCESS';
export const LIKE_POST_FAIL = 'LIKE_POST_FAIL';

export const DISLIKE_POST_REQUEST = 'DISLIKE_POST_REQUEST';
export const DISLIKE_POST_SUCCESS = 'DISLIKE_POST_SUCCESS';
export const DISLIKE_POST_FAIL = 'DISLIKE_POST_FAIL';

export const GET_ONE_POST_REQUEST = 'GET_ONE_POST_REQUEST';
export const GET_ONE_POST_SUCCESS = 'GET_ONE_POST_SUCCESS';
export const GET_ONE_POST_FAIL = 'GET_ONE_POST_FAIL';

export const UPDATE_COMMENTS_COUNT = 'UPDATE_COMMENTS_COUNT';

export interface SearchPostsRequest {
  type: typeof SEARCH_POSTS_REQUEST;
  payload: {};
}
export interface SearchPostsSuccess {
  type: typeof SEARCH_POSTS_SUCCESS;
  payload: { postsList: Post[] };
}
export interface SearchPostsFail {
  type: typeof SEARCH_POSTS_FAIL;
  payload: { error: string };
}
export interface GetPostsRequest {
  type: typeof GET_POSTS_REQUEST;
  payload: {};
}
export interface GetPostsSuccess {
  type: typeof GET_POSTS_SUCCESS;
  payload: { postsList: Post[] };
}
export interface GetPostsFail {
  type: typeof GET_POSTS_FAIL;
  payload: { error: string };
}

interface AddPostRequest {
  type: typeof ADD_POST_REQUEST;
  payload: {};
}

interface AddPostSuccess {
  type: typeof ADD_POST_SUCCESS;
  payload: {
    id: string;
    content: string;
    images?: string[];
    likesCount?: number;
    commentsCount: number;
    uid: string;
    userDisplayName: string;
    profilePicture: string;
  };
}

interface AddPostFail {
  type: typeof ADD_POST_FAIL;
  payload: {
    error: string;
  };
}

interface UpdatePostRequest {
  type: typeof UPDATE_POST_REQUEST;
  payload: {};
}

interface UpdatePostSuccess {
  type: typeof UPDATE_POST_SUCCESS;
  payload: {
    id: string;
    content: string;
    images?: string[];
    userDisplayName: string;
    profilePicture: string;
  };
}

interface UpdatePostFail {
  type: typeof UPDATE_POST_FAIL;
  payload: {
    error: string;
  };
}

interface DeletePostRequest {
  type: typeof DELETE_POST_REQUEST;
  payload: {};
}

interface DeletePostSuccess {
  type: typeof DELETE_POST_SUCCESS;
  payload: {
    id: string;
  };
}
interface DeletePostFail {
  type: typeof DELETE_POST_FAIL;
  payload: {
    id: string;
    error: string;
  };
}

// Like Post
interface LikePostRequest {
  type: typeof LIKE_POST_REQUEST;
  payload: {};
}

interface LikePostSuccess {
  type: typeof LIKE_POST_SUCCESS;
  payload: {
    id: string;
    uid: string;
  };
}

interface LikePostFail {
  type: typeof LIKE_POST_FAIL;
  payload: {
    error: string;
  };
}

// Dislike Post
interface DislikePostRequest {
  type: typeof DISLIKE_POST_REQUEST;
  payload: {};
}

interface DislikePostSuccess {
  type: typeof DISLIKE_POST_SUCCESS;
  payload: {
    id: string;
    uid: string;
  };
}

interface DislikePostFail {
  type: typeof DISLIKE_POST_FAIL;
  payload: {
    error: string;
  };
}

// Get One Post
interface GetOnePostRequest {
  type: typeof GET_ONE_POST_REQUEST;
  payload: {};
}

interface GetOnePostSuccess {
  type: typeof GET_ONE_POST_SUCCESS;
  payload: {
    post: Post;
  };
}

interface GetOnePostFail {
  type: typeof GET_ONE_POST_FAIL;
  payload: {
    error: string;
  };
}

interface UpdateCommentsCount {
  type: typeof UPDATE_COMMENTS_COUNT;
  payload: {
    postId: string;
  };
}

export type PostActionTypes =
  | SearchPostsRequest
  | SearchPostsSuccess
  | SearchPostsFail
  | GetPostsRequest
  | GetPostsSuccess
  | GetPostsFail
  | AddPostRequest
  | AddPostSuccess
  | AddPostFail
  | UpdatePostRequest
  | UpdatePostSuccess
  | UpdatePostFail
  | DeletePostRequest
  | DeletePostSuccess
  | DeletePostFail
  | LikePostRequest
  | LikePostSuccess
  | LikePostFail
  | DislikePostRequest
  | DislikePostSuccess
  | DislikePostFail
  | GetOnePostRequest
  | GetOnePostSuccess
  | GetOnePostFail
  | UpdateCommentsCount;

export interface State {
  postsList: Post[];
  singlePost: Post | null;
  error: string;
}

import firebase from 'firebase/app';

interface CommentType {
  id?: string;
  content: string;
  images?: string[];
  likesCount?: number;
  repliesCount?: number;
  createdAt?: firebase.firestore.Timestamp | Date;
  uid: string;
  userDisplayName: string;
  profilePicture: string;
  likedByIDs?: string[];
  postID?: string;
}

export default class Comment implements CommentType {
  id?: string;
  content: string;
  images?: string[];
  likesCount?: number;
  repliesCount?: number;
  createdAt?: firebase.firestore.Timestamp | Date | null | any;
  uid: string;
  userDisplayName: string;
  profilePicture: string;
  likedByIDs?: string[];
  postID?: string;

  constructor(data?: CommentType) {
    this.id = data?.id ?? '';
    this.content = data?.content ?? '';
    this.images = data?.images ?? [];
    this.likesCount = data?.likesCount ?? 0;
    this.repliesCount = data?.repliesCount ?? 0;
    this.createdAt = data?.createdAt ?? null;
    this.uid = data?.uid ?? '';
    this.userDisplayName = data?.userDisplayName ?? '';
    this.profilePicture = data?.profilePicture ?? '';
    this.likedByIDs = data?.likedByIDs ?? [];
    this.postID = data?.postID ?? '';
  }
}

export const commentConverter: firebase.firestore.FirestoreDataConverter<Comment> =
  {
    toFirestore: (
      comment: Partial<Comment>,
    ): firebase.firestore.DocumentData => {
      return {
        ...comment,
      };
    },
    fromFirestore: (
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ) => {
      const data = snapshot.data(options) as Comment;
      return new Comment({
        id: snapshot.id,
        ...(data as Comment),
      });
    },
  };
